import {Globals}    from './Globals.js';
import {PageH}      from './pageh.js';
import {LoginH}     from './loginh.js';

import './init.js';

import {Order}      from './order.js';
import {OrderHA}    from './orderha.js';
import {OrderH}     from './orderh.js';
import {APICaller}  from './APICaller.js';
import {NewOrders}  from './NewOrders.js';
import {OrderView}  from './OrderView.js';

if (Globals.IS_DEV && false) {
    window.PageH = PageH;
    window.LoginH = LoginH;
}