import {Globals} from './Globals.js';
import {ServerResponseH} from './ServerResponseH.js';

export const APICaller = {};

APICaller.commands = {};

APICaller.commands.checkLoginState = async function() {
    return APICaller.callWebAPI(Globals.const.APICALL.CHECK_LOGIN_STATE);
};

APICaller.commands.login = async function(params) {
    return APICaller.callWebAPI(Globals.const.APICALL.LOGIN, params);
};

APICaller.commands.logout = async function() {
    return APICaller.callWebAPI(Globals.const.APICALL.LOGOUT);
};

APICaller.commands.requestPasswordReset = async function(params) {
    return APICaller.callWebAPI(Globals.const.APICALL.REQUEST_PASSWORD_RESET, params);
};

APICaller.commands.setNewUserPasswordWithHash = async function(params) {
    return APICaller.callWebAPI(Globals.const.APICALL.SET_NEW_USER_PASSWORD_WITH_HASH, params);
};

APICaller.commands.getNewUserActivationStatus = async function() {
    return APICaller.callWebAPI(Globals.const.APICALL.GET_NEW_USER_ACTIVATION_STATUS);
};

APICaller.commands.sendNewUserActivationLink = async function() {
    return APICaller.callWebAPI(Globals.const.APICALL.SEND_NEW_USER_ACTIVATION_LINK);
};

APICaller.commands.setNewPasswordWithHash = async function(params) {
    return APICaller.callWebAPI(Globals.const.APICALL.SET_NEW_PASSWORD_WITH_HASH, params);
};

APICaller.commands.getClients = async function() {
    return APICaller.callWebAPI(Globals.const.APICALL.GET_CLIENTS);
};

APICaller.commands.setCurrentClient = async function(params) {
    return APICaller.callWebAPI(Globals.const.APICALL.SET_CURRENT_CLIENT, params);
};

APICaller.commands.setCurrentClientByOrderId = async function(params) {
    return APICaller.callWebAPI(Globals.const.APICALL.SET_CURRENT_CLIENT_BY_ORDER_ID, params);
};

APICaller.commands.addNewUser = async function(params) {
    return APICaller.callWebAPI(Globals.const.APICALL.ADD_NEW_USER, params);
};

APICaller.commands.updateClient = async function(params) {
    return APICaller.callWebAPI(Globals.const.APICALL.UPDATE_CLIENT, params);
};

APICaller.commands.setOrder = async function(params) {
    return APICaller.callWebAPI(Globals.const.APICALL.SET_ORDER, params);
};

APICaller.commands.getOrder = async function(params) {
    return APICaller.callWebAPI(Globals.const.APICALL.GET_ORDER, params);
};

APICaller.commands.updateOrderStatus = async function(params) {
    return APICaller.callWebAPI(Globals.const.APICALL.UPDATE_ORDER_STATUS, params);
};

APICaller.commands.getActiveOrders = async function() {
    return APICaller.callWebAPI(Globals.const.APICALL.GET_ACTIVE_ORDERS);
};

APICaller.commands.getOrderHistory = function() {
    return APICaller.callWebAPI(Globals.const.APICALL.GET_ORDER_HISTORY);
};

APICaller.commands.getOrdersByStatus = function(params) {
    return APICaller.callWebAPI(Globals.const.APICALL.GET_ORDER_BY_STATUS, params);
};

APICaller.commands.getUserOrdersByStatus = function(params) {
    return APICaller.callWebAPI(Globals.const.APICALL.GET_USER_ORDER_BY_STATUS, params);
};

/**
 * 
 * @param {int[]} params = Array innehållande de orderstatus som ska räknas
 * @returns {result}
 */
APICaller.commands.getOrderCount = function(params) {
    return APICaller.callWebAPI(Globals.const.APICALL.GET_ORDER_COUNT, params);
};

APICaller.callWebAPI = async function(action, params, extra = false) {
    let debugString = "";
    
    if (Globals.const.IS_DEV) {
        debugString = "XDEBUG_SESSION_START=netbeans-xdebug&";
    }
    
    let urlStart = "";
    
    if (Globals.const.IS_DEV) urlStart = "sksorderbackend/";
    
    let url = urlStart + "api/api.php?" + debugString + "action=" + action;
    let result;
    
    try {
        result = await $.ajax({
            url: url,
            type: 'POST',
            data: params
        });
        
        result = ServerResponseH.handleResponse(result);
        result['params'] = extra;
    } catch (error) {
        
    }
    
    return result;
};