import List             from 'list.js'
import {APICaller}      from './APICaller.js';
import {PageH}          from './pageh.js';
import {NotificationH}  from './NotificationH.js';
import {Globals}        from './Globals.js'

export const Clients = {};

Clients.clientList;
Clients.clientListOptions;
Clients.clients;

Clients.init = function() {
    Clients.fetchClients();
};

Clients.fetchClients = async function() {
    let result = await APICaller.commands.getClients();
    
    if (result['code'] === 1) {
        if (result['data'] == 0) {
            Clients.clients = new array();
        } else {
            Clients.clients = JSON.parse(result['data']);
        }
    }
    
    Clients.drawClientList();
};

Clients.drawClientList = function() {
    Clients.clientListOptions = {
        valueNames: [
            'clientListName', 
            'clientListCompany',
            {attr: 'data-email', name: 'clientListEmail'}
        ],
        item: Clients.getClientListTemplate()
    };
    
    Clients.clientList = new List('client-list', Clients.clientListOptions, Clients.getClientsInListformat(Clients.clients));
    
    Clients.setListClickEvents();
    
    PageH.updateMasonry();
};

Clients.setListClickEvents = function() {
    let listItems = $('.clientListUl li');
    
    $(listItems).each(function() {
        let email = $(this).find('.clientListEmail').attr('data-email');
        $(this).on("click", function() {
            Clients.setCurrentClient(email);
        });
    });
    
};

Clients.getClientsInListformat = function(clients) {
    let returnList = new Array();
    
    for (let i = 0; i < clients.length; i++) {
        returnList.push({
            clientListName      : clients[i].firstName + ' ' + clients[i].lastName,
            clientListCompany   : clients[i].company,
            clientListEmail     : clients[i].email
        });
    }
    
    return returnList;
};

Clients.setCurrentClient = async function(email) {
    var params = "data=" + encodeURIComponent(email);
    let result = await APICaller.commands.setCurrentClient(params);
    
    if (result['code'] === 1) {
        if (result['data'] == 0) {
            NotificationH.showSimpleDialog("Error", "Det gick inte att välja kunden. Ladda om sidan och försök igen.");
        } else {
            Globals.setCurrentClient(JSON.parse(result['data']));
            PageH.setClientNameInMenu();
            PageH.openClientMenu();
            PageH.loadPage("user/orderhistory.html", true, "userorderhistorynew");
        }
    }
    
};

Clients.getClientListTemplate = function() {
    return  '<li class="list-group-item p-0">'
                +'<div class="ph-18 pv-8">'
                    +'<div class="container">'
                        +'<div class="row">'
                            +'<div class="col-auto pL-0">'
                                +'<div class="peer">'
                                    +'<img class="w-2r bdrs-50p" src="assets/static/images/abstract-user-flat-1.png" alt="">'
                                +'</div>'
                            +'</div>'
                            +'<div class="col pL-0">'
                                +'<p class="mb-0 mT-2 clientListText clientListEmail"><b class="clientListName">Jäcki Wilson</b></p>'
                                +'<p class="mb-0 clientListText clientListCompany">Hus och sånt</p>'
                            +'</div>'
                        +'</div>'
                    +'</div>'
                +'</div>'
            +'</li>';
};