import {APICaller}      from './APICaller.js';
import {NotificationH}  from './NotificationH.js';
import {PageH}          from './pageh.js';
export const OrderV = {};

OrderV.init = function() {
    
};

OrderV.loadOrderView = function(orderId) {
    PageH.loadPage("user/orderview.html");
};