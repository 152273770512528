export const ValidationH = {};

ValidationH.p      = {};

ValidationH.validateForm = function(form) {
    var returnValue = true;
    var elements = form.getElementsByClassName('validate-element');
    
    for (var i = 0; i < elements.length; i++) {
        if (!ValidationH.validateElement(elements[i])) {
            returnValue = false;
        }
    }
    
    return returnValue;
};

ValidationH.validateElement = function(element) {
    var isValid = true;
    //var input = element.getElementsByTagName('input')[0];
    var input = element;
    var type = input.getAttribute('data-validation-format');

    switch(type) {
        case "":
            if ($(input).val() === "") {
                isValid = false;
            }
            break;
        case "min8":
            if ($(input).val().length < 8) {
                isValid = false;
            }
            break;
        case "email":
            let isNotForced = ($(input).attr("data-validation-no-force") === "true");
            
            if ($(input).val() === "" && isNotForced) {
                break;
            }
            
            var emailRegExp = /[^\s@]+@[^\s@]+\.[^\s@]+/;
            if (!emailRegExp.test($(input).val())) {
                isValid = false;
            }
            break;
        case "personalId":
            var personalIdExp = /[0-9]{6}-[0-9]{4}/;
            if (!personalIdExp.test($(input).val())) {
                isValid = false;
            }
            break;
        case "username":
            var usernameExp = /^[a-zA-Z0-9öäåÖÄÅ._@\-]+$/;
            if (!usernameExp.test($(input).val()) || $(input).val() === "") {
                isValid = false;
            }
            break;
        case "float":
            var floatExp = /[+-]?[0-9]+(\\.[0-9]+)?([Ee][+-]?[0-9]+)?/;
            if (!floatExp.test($(input).val()) || $(input).val() === "") {
                isValid = false;
            }
            break;
        case "radio-checked":
            let checkedInput = $(input).find('input:checked');
            if (checkedInput.length === 0) {
                isValid = false;
            }
            break;
        case "order-item-types":
            let checkedInput2 = $(input).find('input:checked');
            if (checkedInput2.length === 0) {
                isValid = false;
            }
            
            for(let i = 0; i < checkedInput2.length; i++) {
                if ($(checkedInput2[i]).attr("id") === "orderServiceArea") {                    
                    if ($("#areaSelectInput").val() === 0 && checkedInput2[i].length === 1) {
                        isValid = false;
                    }
                    break;
                }
            }
            break;
            
    }
    
    if (!isValid) {
        if (input.getAttribute("type") === "datetime") {
            //input.setAttribute("onchange", "ValidationHandler.p.validateOnElement(this, 'diff')");
            $(element).addClass("did-not-validate");
        } else if(type === "radio") {
            let name = $($('input')[0]).attr('name');
            $('input[name="' + name +'"]').on("input", function(e) {ValidationH.p.validateOnElement(e);});
            $('input[name="' + name +'"]').attr("data-validation-format", "radio");
            let parent = $(input).closest('.validate-element');
            parent.addClass("did-not-validate");
        } else if(type === "radio-checked") {
            $(input).find('input').on("change", function(e) {ValidationH.p.validateOnElement(e);});
            $(input).find('input').attr("data-validation-format", "radio-checked");
            $(input).closest(".validate-element").addClass('did-not-validate');
        } else if(type === "order-item-types") {
            $(input).find('input').on("change", function(e) {ValidationH.p.validateOnElement(e);});
            $(input).find('input').attr("data-validation-format", "order-item-types");
            $(input).closest(".validate-element").addClass('did-not-validate');
        } else {
            $(element).addClass("did-not-validate");
            input.addEventListener("input", ValidationH.p.validateOnElement);
        }
    } else {
        if (type === "radio") {
            let parent = $(input).closest('.validate-element');
            parent.removeClass("did-not-validate");
        }
        $(element).removeClass("did-not-validate");
    }
    
    return isValid;
};

ValidationH.p.validateOnElement = function(e, inputType = "") {
    var input;

    if (inputType === "diff") {
        input = e;
    } else {
        input = e.target;
    }
    var type = input.getAttribute('data-validation-format');
    //var parent  = ValidationH.p.findParent(input);
    var isValid = true;
    
    switch(type) {
        case "":
            if ($(input).val() === "") {
                isValid = false;
            }
            break;
        case "min8":
            if ($(input).val().length < 8) {
                isValid = false;
            }
            break;
        case "email":
            let isNotForced = ($(input).attr("data-validation-no-force") === "true");
            
            if ($(input).val() === "" && isNotForced) {
                break;
            }
            var emailRegExp = /[^\s@]+@[^\s@]+\.[^\s@]+/;
            if (!emailRegExp.test($(input).val())) {
                isValid = false;
            }
            break;
        case "personalId":
            var personalIdExp = /[0-9]{6}-[0-9]{4}/;
            if (!personalIdExp.test($(input).val())) {
                isValid = false;
            }
            break;
        case "username":
            var usernameExp = /[a-zA-Z0-9öäåÖÄÅ._@\-]/;
            if (!usernameExp.test($(input).val()) || $(input).val() === "") {
                isValid = false;
            }
            break;
        case "float":
            var floatExp = /^[+-]?\d+([\.\,]\d+)?$/;
            if (!floatExp.test($(input).val()) || $(input).val() === "") {
                isValid = false;
            }
            break;
        case "radio-checked":
            let parent = $(input).closest(".validate-element");
            let checkedInput = $(parent).find('input:checked');
            if (checkedInput.length === 0) {
                isValid = false;
            }
            input = parent;
            break;
        case "order-item-types":
            let parent2 = $(input).closest(".validate-element");
            let checkedInput2 = $(parent2).find('input:checked');
            if (checkedInput2.length === 0) {
                isValid = false;
            }
            
            for(let i = 0; i < checkedInput2.length; i++) {
                if ($(checkedInput2[i]).attr("id") === "orderServiceArea") {                    
                    if ($("#areaSelectInput").val() === 0 && checkedInput2[i].length === 1) {
                        isValid = false;
                    }
                    break;
                }
            }
            
            input = parent2;
            break;
    }
    
    if (!isValid) {
        $(input).addClass("did-not-validate");
    } else {
        $(input).removeClass("did-not-validate");
    }
};

ValidationH.removeInputEventsFromForm = function(formname) {
    $('#' + formname).find('.validate-element').each(function() {
        this.replaceWith(this.cloneNode(true));
    });
    
    $('#' + formname).find('.did-not-validate').each(function() {
        $(this).removeClass("did-not-validate");
    });
};