import {PageH}          from './pageh.js';
import {Globals}        from './Globals.js';
import {ValidationH}    from './ValidationH.js';
import {NotificationH}  from './NotificationH.js';
import {Client}         from './DataStrucutres.js';
import {APICaller}      from './APICaller.js';

export const AccountS = {};

AccountS.p = {};

AccountS.p.currentUser;

AccountS.p.showAdminProfile = true;

AccountS.init = function() {
    AccountS.p.currentUser = Globals.getCurrentClient(AccountS.p.showAdminProfile);
    $('#updateClientButton').on('click', AccountS.updateButtonEvent);
    AccountS.drawUserInGUI();
};

AccountS.initAsAdmin = function() {
    AccountS.p.showAdminProfile = false;
    $('#clientTypeParent').removeClass("d-none");
    PageH.updateMasonry();
    AccountS.showNewUserLinkButton();
    AccountS.init();
};

AccountS.showNewUserLinkButton = async function() {
    let result = await APICaller.commands.getNewUserActivationStatus();
    
    if (result['code'] === 1) {
        if (result['data'] != 0) {
            $('#sendNewActivationLinkButton').removeClass("d-none");
            $('#sendNewActivationLinkButton').on("click", AccountS.sendNewUserActivationLink);
        }
    }
};

AccountS.sendNewUserActivationLink = async function() {
    let result = await APICaller.commands.sendNewUserActivationLink();
    
    if(result['code'] === 1) {
        if (result['data'] == 1) {
            NotificationH.showSimpleDialog("Länk skickad", 'Länken har skickats till användarens epostadress. Om användaren inte får något email, be användaren att titta igenom sin skräpkorg.');
        } else if (result['data'] == 2){
            NotificationH.showSimpleDialog("Fel uppstod", 'Det gick inte att skicka epost med aktiveringslänk. Kontrollera så att rätt epostadress är inskriven för användaren.');
        } else {
            NotificationH.showSimpleDialog("Fel uppstod", 'Gick inte att hämta aktiveringshash från databasen.');
        }
    } else {
        NotificationH.showSimpleDialog("Okänt fel", 'Något gick fel vid försök till att skicka aktiveringslänk.');
    }
};

AccountS.updateButtonEvent = async function() {
    if (ValidationH.validateForm(document.getElementById('clientForm'))) {
        let client = AccountS.getClientFromGUI();
        let params = "data=" + encodeURIComponent(JSON.stringify(client));
        AccountS.disableForm(true);
        let result = await APICaller.commands.updateClient(params);

        if (result['code'] === 1) {
            if (result['data'] == 1) {
                AccountS.updateCurrentClientInGlobal(client);
                NotificationH.showSimpleDialog("Uppdaterat", "Användaruppgifterna är uppdaterade.");
            } else if(result['data'] == 0) {
                NotificationH.showSimpleDialog("E-post upptagen", "Angiven E-postadress är redan registrerad på en annan kund. Byt E-postadress och försök igen.");
            } else {
                NotificationH.showSimpleDialog("Okänt fel", "Det gick inte att uppdatera användaruppgifterna.");
            }
        }
        
        AccountS.disableForm(false);
    } else {
        NotificationH.showSimpleDialog("Validerade ej", "Inkorrekt information i formuläret.");
    }
};

AccountS.updateCurrentClientInGlobal = function(newInfo) {
    let oldInfo = Globals.getCurrentClient();
    
    oldInfo.accountType = newInfo.accountType;
    oldInfo.company     = newInfo.company;
    oldInfo.email       = newInfo.email;
    oldInfo.firstName   = newInfo.firstName;
    oldInfo.lastName    = newInfo.lastName;
    oldInfo.phoneNumber = newInfo.phoneNumber;
    
};

AccountS.disableForm = function(disable) {
    $( "#clientForm :input" ).prop( "disabled", disable );
};

AccountS.getClientFromGUI = function() {
    let client = new Client();
    client.accountType      = $('input[name="clientType"]:checked').val();
    client.company          = $('#clientCompany').val();
    client.email            = $('#clientEmail').val();
    client.firstName        = $('#clientFirstName').val();
    client.lastName         = $('#clientLastName').val();
    client.phoneNumber      = $('#clientPhone').val();
    
    return client;
};

AccountS.drawUserInGUI = function() {
    //console.log(AccountS.p.currentUser);
    $('input:radio[name="clientType"]').filter('[value="' + AccountS.p.currentUser.accountType + '"]').attr('checked', true);
    $('#clientFirstName').val(AccountS.p.currentUser.firstName);
    $('#clientLastName').val(AccountS.p.currentUser.lastName);
    $('#clientPhone').val(AccountS.p.currentUser.phoneNumber);
    $('#clientEmail').val(AccountS.p.currentUser.email);
    $('#clientCompany').val(AccountS.p.currentUser.company);
};