import {Globals} from './Globals.js';

export const ServerResponseH = {};

ServerResponseH.handleResponse = function(response) {
    response = JSON.parse(response);
    let serverStatus = response['code'];
    try {
        switch(serverStatus) {
            case 2:
                response['code'] = Globals.const.CALL_STATUS_NOT_NORMAL;
                ServerResponseH.showLogin();
                break;
            case 37: 
                response['code'] = Globals.const.CALL_STATUS_NORMAL;
                break;
            default :
                response['code'] = Globals.const.CALL_STATUS_NOT_NORMAL;
                break;
        }
    } catch(e) {
        response['code'] = Globals.const.CALL_STATUS_NOT_NORMAL;
    }
    
    return response;
};

ServerResponseH.showLogin = function() {
    /*LoginHandler.showLogin();
    if (clientInfo.isAndroid) {
        interface.readyForLogin();
    }
    
    if (clientInfo.isIOS) {
        window.webkit.messageHandlers.readyForLogin.postMessage("Ready to login");
    }*/
};