export const Globals = {};

Globals.userInfo;
Globals.userInfoAsAdmin;
Globals.storedOrders = new Array();

Globals.const = {
    IS_DEV: false,
    APICALL: {
        GET_ORDER                       :   1,
        SET_ORDER                       :   2,
        GET_ORDER_HISTORY               :   3,
        GET_USER_ORDER_BY_STATUS        :   4,
        GET_CLIENTS                     : 1001,
        ADD_NEW_USER                    : 1002,
        SET_CURRENT_CLIENT              : 1003,
        GET_ACTIVE_ORDERS               : 1004,
        GET_ORDER_BY_STATUS             : 1005,
        GET_ORDER_COUNT                 : 1006,
        UPDATE_CLIENT                   : 1007,
        SET_CURRENT_CLIENT_BY_ORDER_ID  : 1008,
        UPDATE_ORDER_STATUS             : 1009,
        GET_NEW_USER_ACTIVATION_STATUS  : 1010,
        SEND_NEW_USER_ACTIVATION_LINK   : 1011,
        CHECK_LOGIN_STATE               : 2000,
        LOGIN                           : 2001,
        LOGOUT                          : 2002,
        REQUEST_PASSWORD_RESET          : 2003,
        SET_NEW_PASSWORD_WITH_HASH      : 2004,
        SET_NEW_USER_PASSWORD_WITH_HASH : 2005
    },
    CALL_STATUS_NOT_NORMAL: 0,
    CALL_STATUS_NORMAL:     1,
    
    SPECIAL_ORDER_TYPE_NORMAL:  0,
    SPECIAL_ORDER_TYPE_NOTAR:   1,
    SPECIAL_ORDER_TYPE_LEJON:   2,
    
    ORDER_PAYER_BROKER:     0,
    ORDER_PAYER_SELLER:     1,
    ORDER_PAYER_BUYER:      2,
    
    ORDER_TYPE_NOTAR:                   0,
    ORDER_TYPE_NORMAL:                  1,
    ORDER_TYPE_PLUS:                    2,
    ORDER_TYPE_APARTMENT:               3,
    ORDER_SERVICE_ENERGY:               4,
    ORDER_SERVICE_BUYER_REVIEW_ONSITE:  5,
    ORDER_SERVICE_BUYER_REVIEW_PHONE:   6,
    ORDER_SERVICE_AREA:                 7,
    ORDER_SERVICE_AREA_ATTIC:           8,
    ORDER_SERVICE_AREA_SOUTERRAIN:      9,
    ORDER_TYPE_REINSPECTION:           10,
    ORDER_TYPE_NORMAL_WITH_ENERGY:     11, 
    ORDER_TYPE_PLUS_WITH_ENERGY:       12,
    ORDER_TYPE_LEJON:                  13,
    
    ORDER_STATUS_NEW:                   0,
    ORDER_STATUS_ONGOING:               1,
    ORDER_STATUS_DONE:                  2,
    ORDER_STATUS_CANCELLED:             4,
    
    ACCOUNT_TYPE_BROKER:                1,
    ACCOUNT_TYPE_NOTAR:                 2,
    ACCOUNT_TYPE_LEJON:                 3,
    ACCOUNT_TYPE_PRIVATE:               4
};

Globals.isAdmin = function() {
    if (Globals.userInfo.permissionLevel === 0) {
        return true;
    } else {
        return false;
    }
};

Globals.setCurrentClient = function(client) {
    if (Globals.isAdmin()) {
        Globals.userInfoAsAdmin = client;
    } else {
        Globals.userInfo = client;
    }
};

Globals.getCurrentClient = function(forceAdminData = false) {
    if (Globals.isAdmin() && forceAdminData === false) {
        return Globals.userInfoAsAdmin;
    } else {
        return Globals.userInfo;
    }
};

Globals.addStoreOrders = function(orderArray) {
    orderArray.forEach((order) => {
        Globals.storedOrders[order.orderId] = order;
    });

    /*$.each(orderArray, function (i, order) {
        Globals.storedOrders[order.orderId] = order;
    });*/
    
    /*$(orderArray).each(function (i, order) {
        Globals.storedOrders[this.orderId] = this;
    });*/
};

Globals.getStoredOrder = function(orderId) {
    if(typeof Globals.storedOrders[orderId] === 'undefined') {
        return 0;
    }
    else {
        return Globals.storedOrders[orderId];
    }
};