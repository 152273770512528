import {ValidationH}    from './ValidationH.js';
import {APICaller}      from './APICaller.js';
import {Globals}        from './Globals.js';
import {NotificationH}  from './NotificationH.js';

export const AddClient = {};

AddClient.init = function() {
    $('.newClientTypeChoice').on('click', function() {AddClient.clientTypeEvent();});
    $('#newCLientAddClientButton').on('click', function() { AddClient.addNewClient(); });
};

AddClient.clientTypeEvent = function() {
    let type = $('input[name="newClientType"]:checked').val();
    
    switch(type) {
        case 'private':
            $('.addClientCompany').hide(100);
            break;
        default:
            $('.addClientCompany').show(100);
            break;
    }
};

AddClient.addNewClient = async function() {
    if (ValidationH.validateForm(document.getElementById("newClientForm"))) {
        $('#newCLientAddClientButton').prop("disabled", true);
        var formAsJson = AddClient.readForm($('#newClientForm')[0]);
        formAsJson = encodeURIComponent(JSON.stringify(formAsJson));
        var params = "data=" + formAsJson;
        
        let response = await APICaller.commands.addNewUser(params);
        
        if (response['code'] === Globals.const.CALL_STATUS_NORMAL) {
            if (response['data'] == 1) {
                document.getElementById('newClientForm').reset();
                NotificationH.showSimpleDialog("Kund skapad", "Kunden har fått ett email med en aktiveringslänk. Innan kunden kan använda systemet måste hen skapa ett lösenord via aktiveringslänken.");
            } else if (response['data'] == 0) {
                NotificationH.showSimpleDialog("Kund finns redan", "Epostadressen finns redan registrerad i systemet. Om kunden har glömt sitt lösenord så kan det återställas via inloggningsdialogen.");
            } else if (response['data'] == 2) {
                document.getElementById('newClientForm').reset();
                NotificationH.showSimpleDialog("Kunde inte skicka email", "Kunden har skapats men det gick inte att skicka email med aktiveringslänk. Gå till kundens inställningar för att skicka aktiveringslänken manuellt eller för att försöka skicka email igen.");
            } else if (response['data'] == 3) {
                document.getElementById('newClientForm').reset();
                NotificationH.showSimpleDialog("ALLVALIGT FEL!!!", "Kunden är skapad men det gick inte att skapa en aktiveringslänk. Kontakta systemadministratör.");
            } else {
                NotificationH.showSimpleDialog("Okänt fel!", "Ett fel inträffade och kunden kunde inte skapas.");
            }
        }
        
    }
    
    $('#newCLientAddClientButton').prop("disabled", false);
};



AddClient.readForm = function(form) {
    let formData = new FormData(form);
    
    let returnObject = {
        accountType: formData.get('newClientType'),
        firstName: formData.get('newClientFirstName'),
        lastName: formData.get('newClientLastName'),
        phoneNumber: formData.get('newClientPhone'),
        email: formData.get('newClientEmail'),
        company: formData.get('newClientCompany')
    };
    
    return returnObject;
    
};