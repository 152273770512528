import List         from 'list.js'
import {APICaller}  from './APICaller.js';
import {PageH}      from './pageh.js';
import {OrderV}  from './OrderView.js';

export const NewOrders = {};


NewOrders.ordersList;
NewOrders.ordersListOptions;
NewOrders.orders;

NewOrders.init = function() {
    NewOrders.fetchOrders();
};

NewOrders.fetchOrders = async function() {
    /*let result = await APICaller.commands.getOrderHistoryAll();
    
    if (result['code'] === 1) {
        if (result['data'] == 0) {
            Clients.clients = new array();
        } else {
            Clients.clients = JSON.parse(result['data']);
        }
    }
    */
    NewOrders.createMockOrder();
    NewOrders.drawOrderList();
};

NewOrders.drawOrderList = function() {
    NewOrders.ordersListOptions = {
        valueNames: ['orderListName', 'orderListCompany', 'orderListObject', 'orderListDate', {data: ['id']}],
        item: NewOrders.getOrderListTemplate()
    };
    
    NewOrders.ordersList = new List('order-list', NewOrders.ordersListOptions, NewOrders.getOrdersInListformat(NewOrders.orders));

    NewOrders.setClickEvent();
    
    PageH.updateMasonry();
};

NewOrders.setClickEvent = function() {
    let items = $(".list-group-item");
    
    $(items).each(function() {
        $(this).on("click", function() {
            OrderV.loadOrderView($(this).attr("data-id"));
        });
    });
};

NewOrders.getOrdersInListformat = function(orders) {
    let returnList = new Array();
    
    for (let i = 0; i < orders.length; i++) {
        returnList.push({
            orderListName       : orders[i].firstName + ' ' + orders[i].lastName,
            orderListCompany    : orders[i].company,
            orderListObject     : orders[i].object,
            orderListDate       : orders[i].date,
            id                  : orders[i].id
        });
    }
    
    return returnList;
};

NewOrders.getOrderListTemplate = function() {
    return '<li class="list-group-item" data-id="">'
                +'<div class="container">'
                    +'<div class="row">'
                        +'<div class="col-auto pL-0">'
                            +'<div class="peer">'
                                +'<img class="w-2r bdrs-50p" src="assets/static/images/abstract-user-flat-1.png" alt="">'
                            +'</div>'
                        +'</div>'
                        +'<div class="col-3 pL-0">'
                            +'<p class="mb-0 mT-2 clientListText"><b class="orderListName">Jäcki Wilson</b></p>'
                            +'<p class="mb-0 clientListText orderListCompany">Hus och sånt</p>'
                        +'</div>'
                        +'<div class="col pL-0">'
                            +'<p class="mb-0 mT-2 clientListText"><b class="orderListObject">Blåklintdalen 43</b></p>'
                            +'<p class="mb-0 clientListText orderListDate">2023-02-25</p>'
                        +'</div>'
                        +'<div class="col-auto pL-0">'
                            +'<span class="icon-holder">'
                                //+'<i class="fa-solid fa-circle"></i>'
                                +'<i class="c-deep-orange-500 ti-control-record"></i>'
                            +'</span>'
                        +'</div>'
                    +'</div>'
                +'</div>'
            +'</li>';
};

NewOrders.createMockOrder = function() {
    let orders = new Array();
    
    let object1 = {
        firstName   : "Inna",
        lastName    : "Aldriktsson",
        company     : "Mäklarna",
        object      : "Stenskönan 145",
        date        : "2023-07-23",
        id          : 1
    };
    
    orders.push(object1);
    
    let object2 = {
        firstName   : "Alexander",
        lastName    : "Halmström",
        company     : "Sköna hem",
        object      : "Per lagers bottenvik 5",
        date        : "2023-06-19",
        id          : 2
    };
    
    orders.push(object2);
    NewOrders.orders = orders;
};