import {LoginH} from './loginh';
import {PageH} from './pageh.js';
import {APICaller} from './APICaller.js';
import {Globals} from './Globals.js';
import moment from 'moment';

export default(async function() {
        if (await LoginH.doLoginCheck()) {
            let response = await APICaller.commands.checkLoginState();

            if (response['code'] === 1) {
                if (response['data'] != false) {
                    Globals.userInfo = JSON.parse(response['data']);

                    if (Globals.isAdmin()) {
                        PageH.loadSideBar('admin');
                        PageH.getNewOrdersCount();
                        PageH.getOngoingOrdersCount();
                        PageH.loadPage("admin/orderhistoryall.html", true, "orderhistorynew");
                    } else if (Globals.userInfo.permissionLevel === 1) {
                        PageH.loadSideBar('user');
                        PageH.loadPage("user/order.html", true, "order");
                    }
                }
                PageH.setNavRight();
            } else {
                LoginH.showLoginModal();
            }
        }
        
        $('#copyrightYear').html(moment().format("YYYY"));
        
}());