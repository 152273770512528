export class OrderDS {
    orderId = -1;
    personId;
    status;
    specialType;
    payer;
    wantInsurance;
    brokerOffice;
    broker;
    brokerPhone;
    brokerEmail;
    objectProperty;
    objectAddress;
    objectPostNumber;
    objectPlace;
    message;
    orderItems  = [];
    clients     = [];
    createdAt;
    updatedAt;

    
    
    constructor() {
        
    }
}

export class OrderItem {
    id;
    item;
    itemName;
    price;

    constructor() {
        
    }
}

export class OrderClient {

    id;
    first_name;
    last_name;
    person_number;
    phone_number;
    email;
    address;
    post_number;
    place;
    
    constructor() {
        
    }
    
    isEmpty() {
        if ((typeof this.id === 'undefined' || this.id === "")
                && this.first_name === ""
                && this.last_name === ""
                && this.person_number === ""
                && this.phone_number === ""
                && this.email === ""
                && this.address === ""
                && this.post_number === ""
                && this.place === "") {
            
            return true;
        } else {
            return false;
        }
    }
    
}

export class Client {
    accountType;
    company;
    email;
    firstName;
    lastName;
    internalID;
    permissionLevel;
    phoneNumber;
    
    constructor() {
        
    }
}