import {APICaller}                      from './APICaller.js';
import {Globals}                        from './Globals.js';
import {PageH}                          from './pageh.js';
import {OrderDS,OrderItem,OrderClient}  from './DataStrucutres.js';
import {ValidationH}                    from './ValidationH.js';
import {NotificationH}                  from './NotificationH.js';

export const Order = {};

Order.p = {};

Order.p.dAreaPrice50        = 1950;
Order.p.dAreaPrice100       = 2600;
Order.p.dAreaPrice150       = 3400;
Order.p.dAreaPrice300       = 4250;
Order.p.dPriceNotar         = 9975;
Order.p.dPriceLejon         = 8250;
Order.p.dPriceNormal        = 7000;
Order.p.dPriceNormalEnergy  = 9800;
Order.p.dPricePlus          = 8000;
Order.p.dPricePlusEnergy    = 10500;
Order.p.dPriceReinspection  = 5000;
Order.p.dPriceApartment     = 3500;
Order.p.dPriceEnergy        = 3975;
Order.p.dPriceReviewOnsite  = 3000;
Order.p.dPriceReviewPhone   = 1000;
Order.p.dPriceAttic         = 700;
Order.p.dPriceSouterrain    = 700;

Order.p.areaDiscount            = 1000;
Order.p.inspectionIsSelected    = false;

Order.p.currentClientAutofill = "";

Order.p.validationActive = false;
Order.p.isOrderUpdate = false;

Order.currentOrder = {};

Order.init = function() {
    Order.p.validationActive = false;
    Order.p.isOrderUpdate = false;
    Order.resetPrices();
    Order.currentOrder = {};
    Order.setEvents();
    Order.setRealtorInfo();
    Order.areaDrawOptionText(false);
    //Order.drawOrder();
};



Order.resetPrices = function() {
    $("#orderTypeNotarCost").html(Order.p.dPriceNotar + ":-");
    $("#orderTypeLejonCost").html(Order.p.dPriceLejon + ":-");
    $("#orderTypeNormalCost").html(Order.p.dPriceNormal + ":-");
    $("#orderTypeNormalEnergyCost").html(Order.p.dPriceNormalEnergy + ":-");
    $("#orderTypePlusCost").html(Order.p.dPricePlus + ":-");
    $("#orderTypePlusEnergyCost").html(Order.p.dPricePlusEnergy + ":-");
    $("#orderTypeReinspectionCost").html(Order.p.dPriceReinspection + ":-");
    $("#orderTypeApartmentCost").html(Order.p.dPriceApartment + ":-");
    $("#orderServiceEnergyCost").html(Order.p.dPriceEnergy + ":-");
    $("#orderServiceBuyerReviewOnSiteCost").html(Order.p.dPriceReviewOnsite + ":-");
    $("#orderServiceBuyerReviewPhoneCost").html(Order.p.dPriceReviewPhone + ":-");
    $("#orderServiceAreaAtticCost").html(Order.p.dPriceAttic + ":-");
    $("#orderServiceAreaSouterrainCost").html(Order.p.dPriceSouterrain + ":-");
    $("#orderServiceAreaCost").html("");
    Order.areaDrawOptionText(false);
};

Order.setEvents = function() {
    $('.orderTypeChoice').on('click', function(){ Order.orderTypeEvent(this); });
    $('#orderServiceAreaParent').on('click', function() { Order.showHideAreaInfo(); });
    //$('.orderTypeEvent').on('click', function() {Order.updateTotalCost();});
    $('#areaSelectInput').on('focus', function() {Order.areaDrawOptionText(true);});
    $('#areaSelectInput').on('focusout', function() {Order.areaDrawOptionText(false);});
    $('#areaSelectInput').on('change', function() {Order.updateAreaSelection();});
    $('.orderCostItem').on('change', function() {Order.updateTotalCost();});
    $('#orderButton').on('click', function() {Order.orderButtonEvent();});
    //$('.serviceReview').on('change', function(e) {Order.serviceReviewEvent(e);});
    $('.clientAdressBox').on('change', function(e){Order.clientAdressBoxEvent(e);});
    $('#objectAddress').on('input', Order.updateClientAddressAutofill);
    $('#objectPostNumber').on('input', Order.updateClientAddressAutofill);
    $('#objectPostAddress').on('input', Order.updateClientAddressAutofill);
    $('.orderStatusButton').on('click', function(){ Order.changeOrderStatusEvent(this);});
};

Order.updateClientAddressAutofill = function() {
    let clientCBs = $('.clientAdressBox');
        
    for (let i = 0; i < clientCBs.length; i++) {
        if (clientCBs[i].checked) {
            let clientFill = "secondC";
            
            if ($(clientCBs[i]).attr('id') === "clientAdressBox") {
                clientFill = "c";
            }
            
            Order.clientAutofill(clientFill);
        }
    }
};

Order.clientAdressBoxEvent = function(e) {
    let element = e;
    
    if (e.target) {
        element = e.target;
    }
    let clientFill = "c";
    
    if ($(element).attr("id") === "secondClientAdressBox") {
        clientFill = "secondC";
    }
    
    if (element.checked) {
        Order.updateClientAddressAutofill();
        Order.disableClientAddress(clientFill, true);
    } else {
        Order.disableClientAddress(clientFill, false);
        Order.clearClientAddress(clientFill);
    }
};

Order.clearClientAddress = function(client) {
    $('#' + client + 'lientAddress').val("");
    $('#' + client + 'lientPostnumber').val("");
    $('#' + client + 'lientPostAddress').val("");
    
    if (Order.p.validationActive) {
        ValidationH.p.validateOnElement($('#' + client + 'lientAddress')[0], "diff");
        ValidationH.p.validateOnElement($('#' + client + 'lientPostnumber')[0], "diff");
        ValidationH.p.validateOnElement($('#' + client + 'lientPostAddress')[0], "diff");
    }
};

Order.disableClientAddress = function(client, disable) {
    $('#' + client + 'lientAddress').prop("disabled", disable);
    $('#' + client + 'lientPostnumber').prop("disabled", disable);
    $('#' + client + 'lientPostAddress').prop("disabled", disable);
};

Order.clientAutofill = function(client) {
    $('#' + client + 'lientAddress').val($('#objectAddress').val());
    $('#' + client + 'lientPostnumber').val($('#objectPostNumber').val());
    $('#' + client + 'lientPostAddress').val($('#objectPostAddress').val());
    
    if (Order.p.validationActive) {
        ValidationH.p.validateOnElement($('#' + client + 'lientAddress')[0], "diff");
        ValidationH.p.validateOnElement($('#' + client + 'lientPostnumber')[0], "diff");
        ValidationH.p.validateOnElement($('#' + client + 'lientPostAddress')[0], "diff");
    }
};

Order.serviceReviewEvent = function(e) {
    $('.serviceReview').each(function() {
        if (this !== e.target) {
            $(this).prop("checked", false);
        }
    });
    
    Order.updateTotalCost();
};

Order.disableForm = function(disable) {
    $( "#order-form :input" ).prop( "disabled", disable );
    
    if (!disable) {
        $('.clientAdressBox').each(function() {
            Order.clientAdressBoxEvent(this);
        });
        
        let selectedItemNotFound = true;
        $( ".orderTypeChoice" ).each(function() {
            if ($(this).find('input').prop("checked") === true) {
                Order.orderTypeEvent(this);
                selectedItemNotFound = false;
            }
            return selectedItemNotFound;
        });
    }
};

Order.resetForm = function() {
    $('#order-form').trigger("reset");
    Order.resetPrices();
    Order.showHideAreaInfo();
    $('.clientAdressBox').each(function() {
        $(this).prop("checked", false);
        let clientFill = "c";

        if ($(this).attr('id') === "clientAdressBox") {
            clientFill = "secondC";
        }
        
        Order.disableClientAddress(clientFill, false);
    });
    
    ValidationH.removeInputEventsFromForm("order-form");
    Order.p.validationActive = false;
};

Order.orderButtonEvent = async function() {
    if (ValidationH.validateForm(document.getElementById("order-form"))) {
        let order = Order.getOrderFromGUI();
        if (Order.p.isOrderUpdate) {
            order.orderId = Order.currentOrder.orderId;
        }
        
        let params = "data=" + encodeURIComponent(JSON.stringify(order));
        Order.disableForm(true);
        let result = await APICaller.commands.setOrder(params);

        if (result['code'] === 1) {
            if (result['data'] == 1) {
                if (Order.p.isOrderUpdate) {
                    NotificationH.showSimpleDialog("Uppdaterad", "Ordern har uppdaterats.");
                } else {
                    Order.resetForm();
                    NotificationH.showSimpleDialog("Beställningen genomförd", "Vi kommer kontakta dig med en orderbekräftelse och besiktningsdatum inom kort.");
                }
            } else {
                if (Order.p.isOrderUpdate) {
                    NotificationH.showSimpleDialog("Okänt fel", "Det gick inte att uppdatera beställningen.");
                } else {
                    NotificationH.showSimpleDialog("Okänt fel", "Det gick inte att genomföra beställningen.");
                }
                Order.p.validationActive = true;
            }
        }
        Order.disableForm(false);
    } else {
        NotificationH.showSimpleDialog("Saknas information", "Se över beställningen. Och försök sedan igen.");
        Order.p.validationActive = true;
    }
};

Order.getOrderFromGUI = function() {
    let order = new OrderDS();
    //order.specialType       = $('input[name="specialOrderType"]:checked').val();
    order.payer             = $('input[name="orderPayer"]:checked').val();
    order.orderItems        = Order.getOrderItemsFromGUI();
    order.wantInsurance     = $('input[name="garboContact"]:checked').val();
    order.brokerOffice      = $('#brokerInfoOffice').val();
    order.broker            = $('#brokerInfoBroker').val();
    order.brokerPhone       = $('#brokerInfoPhone').val();
    order.brokerEmail       = $('#brokerInfoEmail').val();
    order.objectProperty    = $('#objectProperty').val();
    order.objectAddress     = $('#objectAddress').val();
    order.objectPostNumber  = $('#objectPostNumber').val();
    order.objectPlace       = $('#objectPostAddress').val();
    order.clients           = Order.getOrderClientsFromGUI();
    order.message           = $('#extraInfo').val();

    return order;
};

Order.getOrderClientsFromGUI = function() {
    let clients = [];
    
    for (let i = 0; i < 2; i++) {
        let client = new OrderClient();
        let second = "c";
        
        if (i === 1) {
            second = "secondC";
        }
        
        client.first_name       = $('#' + second + 'lientFirstName').val();
        client.last_name        = $('#' + second + 'lientLastName').val();
        client.person_number    = $('#' + second + 'lientPersonalID').val();
        client.phone_number     = $('#' + second + 'lientPhone').val();
        client.email            = $('#' + second + 'lientEmail').val();
        client.address          = $('#' + second + 'lientAddress').val();
        client.post_number      = $('#' + second + 'lientPostnumber').val();
        client.place            = $('#' + second + 'lientPostAddress').val();
        
        if (!client.isEmpty()) {
            clients.push(client);
        }
    }
    
    return clients;
};

Order.getOrderItemsFromGUI = function() {
    let items = [];
    let elements = $('.orderCostItem:checked');
    
    for (let i = 0; i < elements.length; i++) {
        let item = new OrderItem();
        let itemId = $(elements[i]).attr("id");
        
        item.item       = Order.getOrderItemValueByInputId(itemId);
        item.itemName   = $($(elements[i]).next('label')).find('span')[0].innerHTML;
        item.price      = $('#' + itemId + 'Cost').html().replace(":-", "");
        
        if (itemId === "orderServiceArea") {
            item.itemName = $("#areaSelectInput").val();
        } 
        
        items.push(item);
    }
    
    return items;
};

Order.getOrderItemValueByInputId = function(inputId) {
    switch (inputId) {
        case 'orderTypeNotar': return Globals.const.ORDER_TYPE_NOTAR;
            break;
        case 'orderTypeLejon': return Globals.const.ORDER_TYPE_LEJON;
            break;
        case 'orderTypeNormal': return Globals.const.ORDER_TYPE_NORMAL;
            break;
        case 'orderTypeNormalEnergy': return Globals.const.ORDER_TYPE_NORMAL_WITH_ENERGY;
            break;
        case 'orderTypePlus': return Globals.const.ORDER_TYPE_PLUS;
            break;
        case 'orderTypePlusEnergy': return Globals.const.ORDER_TYPE_PLUS_WITH_ENERGY;
            break;
        case 'orderTypeReinspection': return Globals.const.ORDER_TYPE_REINSPECTION;
            break;
        case 'orderTypeApartment': return Globals.const.ORDER_TYPE_APARTMENT;
            break;
        case 'orderServiceEnergy': return Globals.const.ORDER_SERVICE_ENERGY;
            break;
        case 'orderServiceBuyerReviewOnSite': return Globals.const.ORDER_SERVICE_BUYER_REVIEW_ONSITE;
            break;
        case 'orderServiceBuyerReviewPhone': return Globals.const.ORDER_SERVICE_BUYER_REVIEW_PHONE;
            break;
        case 'orderServiceArea': return Globals.const.ORDER_SERVICE_AREA;
            break;
        case 'orderServiceAreaAttic': return Globals.const.ORDER_SERVICE_AREA_ATTIC;
            break;
        case 'orderServiceAreaSouterrain': return Globals.const.ORDER_SERVICE_AREA_SOUTERRAIN;
            break;
    }
};

Order.loadOrder = function(oId) {
    $('#orderButton').html("Uppdatera");
    
    let order = Globals.getStoredOrder(oId);

    if (order === 0) {
        Order.fetchOrderById(oId);
    } else {
        Order.currentOrder = order;
        Order.drawOrder();
    }
    
    Order.p.isOrderUpdate = true;
    
    Order.showOrderStatusButtons();
};

Order.showOrderStatusButtons = function() {
    if (Globals.isAdmin()) {
        $('.orderStatusButton').show();
    }
};

Order.fetchOrderById = async function(orderId) {
    let params = "data=" + encodeURIComponent(orderId);
    
    let result = await APICaller.commands.getOrder(params);
    
    if (result['code'] === 1) {
        if (result['data'] == 0) {
            Order.currentOrder = {};
        } else {
            Order.currentOrder = JSON.parse(result['data']);
        }
    }
    
    Order.drawOrder();
};

Order.drawOrder = function() {
    //Order.setSpecilOrderType(Order.currentOrder.specialType);
    Order.setPayer(Order.currentOrder.payer);
    Order.setTypes(Order.currentOrder.orderItems);
    Order.setInsurance(Order.currentOrder.wantInsurance);
    Order.setRealtorInfo();
    Order.setObject();
    Order.setClients(Order.currentOrder.clients);
    Order.setMessage(Order.currentOrder.message);
    Order.renderOrderButtons();
    
    //console.log(Order.currentOrder);
};

Order.renderOrderButtons = function() {
    $('.orderStatusButton').prop('disabled', false);
    
    switch(Order.currentOrder.status) {
        case Globals.const.ORDER_STATUS_NEW:
            $('#orderStatusNewButton').prop('disabled', true);
            break;
        case Globals.const.ORDER_STATUS_ONGOING:
            $('#orderStatusOngoingButton').prop('disabled', true);
            break;
        case Globals.const.ORDER_STATUS_DONE:
            $('#orderStatusDoneButton').prop('disabled', true);
            break;
    }
};

Order.changeOrderStatusEvent = async function(button) {
    let buttonName = $(button).prop("id");
    let statusType;
    switch(buttonName) {
        case "orderStatusNewButton":
            statusType = Globals.const.ORDER_STATUS_NEW;
            break;
        case "orderStatusOngoingButton":
            statusType = Globals.const.ORDER_STATUS_ONGOING;
            break;
        case "orderStatusDoneButton":
            statusType = Globals.const.ORDER_STATUS_DONE;
            break;
    }
    
    let status = {
        orderStatus : statusType,
        orderId     : Order.currentOrder.orderId
    };
    
    let params = "data=" + encodeURIComponent(JSON.stringify(status));

    let result = await APICaller.commands.updateOrderStatus(params);

    if (result['code'] === 1) {
        if (result['data'] == 1) {
            PageH.drawOrderCounts();
            NotificationH.showSimpleDialog("Uppdaterad", "Beställningsstatus har uppdaterats.");
            Order.currentOrder.status = statusType;
            Order.renderOrderButtons();
        } else {
            NotificationH.showSimpleDialog("Okänt fel", "Det gick inte att uppdatera beställningsstatus.");
        }
    }
    
    
};

Order.setMessage = function(message)  {
    $('#extraInfo').val(message);
};

Order.setClients = function(clients) {
    for (let i = 0; i < clients.length; i++) {
        let second = "c";
        
        if (i > 0) {
            second = "secondC";
        }
        
        if (clients[i].address === $('#objectAddress').val() 
                && clients[i].post_number === $('#objectPostNumber').val() 
                && clients[i].place === $('#objectPostAddress').val() 
                && $('#objectAddress').val() !== ""
                && $('#objectPostNumber').val() !== ""
                && $('#objectPostAddress').val() !== "") {
            $('#' + second + 'lientAdressBox').prop('checked', true);
        }
        
        $("#" + second + "lientFirstName").val(clients[i].first_name);
        $("#" + second + "lientLastName").val(clients[i].last_name);
        $("#" + second + "lientPersonalID").val(clients[i].person_number);
        $("#" + second + "lientPhone").val(clients[i].phone_number);
        $("#" + second + "lientEmail").val(clients[i].email);
        $("#" + second + "lientAddress").val(clients[i].address);
        $("#" + second + "lientPostnumber").val(clients[i].post_number);
        $("#" + second + "lientPostAddress").val(clients[i].place);
        
        Order.clientAdressBoxEvent($('#' + second + 'lientAdressBox')[0]);
    }
};

Order.setObject = function() {
    $('#objectProperty').val(Order.currentOrder.objectProperty);
    $('#objectAddress').val(Order.currentOrder.objectAddress);
    $('#objectPostNumber').val(Order.currentOrder.objectPostNumber);
    $('#objectPostAddress').val(Order.currentOrder.objectPlace);
};

Order.setInsurance = function(wantInsurance) {
    if (wantInsurance == 1) {
        $('#garboContactYes').prop("checked", true);
    } else {
        $('#garboContactNo').prop("checked", true);
    }
};

Order.setTypes = function(items) {
    for (let i = 0; i < items.length; i++) {
        switch(items[i].item) {
            case Globals.const.ORDER_TYPE_NOTAR: $('#orderTypeNotar').prop("checked", true);
                Order.setPrice("orderTypeNotarCost", Math.floor(items[i].price));
                Order.orderTypeEvent($('#orderTypeNotar').closest(".orderTypeChoice")[0]);
                break;
            case Globals.const.ORDER_TYPE_LEJON: $('#orderTypeLejon').prop("checked", true);
                Order.setPrice("orderTypeLejonCost", Math.floor(items[i].price));
                Order.orderTypeEvent($('#orderTypeLejon').closest(".orderTypeChoice")[0]);
                break;
            case Globals.const.ORDER_TYPE_NORMAL: $('#orderTypeNormal').prop("checked", true);
                Order.setPrice("orderTypeNormalCost", Math.floor(items[i].price));
                Order.orderTypeEvent($('#orderTypeNormal').closest(".orderTypeChoice")[0]);
                break;
            case Globals.const.ORDER_TYPE_NORMAL_WITH_ENERGY: $('#orderTypeNormalEnergy').prop("checked", true);
                Order.setPrice("orderTypeNormalEnergyCost", Math.floor(items[i].price));
                Order.orderTypeEvent($('#orderTypeNormalEnergy').closest(".orderTypeChoice")[0]);
                break;
            case Globals.const.ORDER_TYPE_PLUS: $('#orderTypePlus').prop("checked", true);
                Order.setPrice("orderTypePlusCost", Math.floor(items[i].price));
                Order.orderTypeEvent($('#orderTypePlus').closest(".orderTypeChoice")[0]);
                break;
            case Globals.const.ORDER_TYPE_PLUS_WITH_ENERGY: $('#orderTypePlusEnergy').prop("checked", true);
                Order.setPrice("orderTypePlusEnergyCost", Math.floor(items[i].price));
                Order.orderTypeEvent($('#orderTypePlusEnergy').closest(".orderTypeChoice")[0]);
                break;
            case Globals.const.ORDER_TYPE_REINSPECTION: $('#orderTypeReinspection').prop("checked", true);
                Order.setPrice("orderTypeReinspectionCost", Math.floor(items[i].price));
                Order.orderTypeEvent($('#orderTypeReinspection').closest(".orderTypeChoice")[0]);
                break;
            case Globals.const.ORDER_TYPE_APARTMENT: $('#orderTypeApartment').prop("checked", true);
                Order.setPrice("orderTypeApartmentCost", Math.floor(items[i].price));
                Order.orderTypeEvent($('#orderTypeApartment').closest(".orderTypeChoice")[0]);
                break;
            case Globals.const.ORDER_SERVICE_ENERGY: $('#orderServiceEnergy').prop("checked", true);
                Order.setPrice("orderServiceEnergyCost", Math.floor(items[i].price));
                break; 
            case Globals.const.ORDER_SERVICE_BUYER_REVIEW_ONSITE: $('#orderServiceBuyerReviewOnSite').prop("checked", true);
                Order.setPrice("orderServiceBuyerReviewOnSiteCost", Math.floor(items[i].price));
                Order.orderTypeEvent($('#orderServiceBuyerReviewOnSite').closest(".orderTypeChoice")[0]);
                break;
            case Globals.const.ORDER_SERVICE_BUYER_REVIEW_PHONE: $('#orderServiceBuyerReviewPhone').prop("checked", true);
                Order.setPrice("orderServiceBuyerReviewPhoneCost", Math.floor(items[i].price));
                Order.orderTypeEvent($('#orderServiceBuyerReviewPhone').closest(".orderTypeChoice")[0]);
                break;
            case Globals.const.ORDER_SERVICE_AREA: $('#orderServiceArea').prop("checked", true);
                Order.showHideAreaInfo();
                $("#areaSelectInput").val(items[i].itemName).change();
                Order.setPrice("orderServiceAreaCost", Math.floor(items[i].price));
                break;
            case Globals.const.ORDER_SERVICE_AREA_ATTIC: $('#orderServiceAreaAttic').prop("checked", true);
                Order.setPrice("orderServiceAreaAtticCost", Math.floor(items[i].price));
                break;
            case Globals.const.ORDER_SERVICE_AREA_SOUTERRAIN: $('#orderServiceAreaSouterrain').prop("checked", true);
                Order.setPrice("orderServiceAreaSouterrainCost", Math.floor(items[i].price));
                break;
        }
    }
};

Order.setPrice = function(element, price) {
    $("#" + element).html(price + ":-");
};

Order.setPayer = function(payer) {

    switch(payer) {
        case Globals.const.ORDER_PAYER_BROKER:  $('#orderPayerBroker').prop("checked", true).trigger("change");
            break;
        case Globals.const.ORDER_PAYER_SELLER:  $('#orderPayerSeller').prop("checked", true).trigger("change");
            break;
        case Globals.const.ORDER_PAYER_BUYER:   $('#orderPayerBuyer').prop("checked", true).trigger("change");
            break;
    }
};

/*Order.setSpecilOrderType = function(type) {
    switch(type) {
        case Globals.const.SPECIAL_ORDER_TYPE_NORMAL: $('#specialOrderTypeStandard').prop("checked", true);
            break;
        case Globals.const.SPECIAL_ORDER_TYPE_NOTAR: $('#specialOrderTypeNotar').prop("checked", true);
            break;
    }
    
    Order.specialOrderTypeEvent();
};*/

Order.updateTotalCost = function() {
    let total = 0;
    
    let checkedOrderCostItems = $('.costItemAutoCheck:checked');
    
    for (let i = 0; i < checkedOrderCostItems.length; i++) {
        total += Order.getPriceFromCostItem(checkedOrderCostItems[i]);
    }
    
    let areaIsChecked = document.getElementById("orderServiceArea").checked;
    
    if (areaIsChecked) {
        let areaValue = parseInt($('#orderServiceAreaCost').html(), 10)
        if (!Number.isNaN(areaValue)) {
            total += areaValue;
        }
        
        let atticInput = document.getElementById("orderServiceAreaAttic");
        
        if (atticInput.checked) {
            total += Order.getPriceFromCostItem(atticInput);
        }
        
        let souterrainInput = document.getElementById("orderServiceAreaSouterrain");
        
        if (souterrainInput.checked) {
            total += Order.getPriceFromCostItem(souterrainInput);
        }
        
        
    }
    
    $('#totalCost').html(total);
};

Order.getPriceFromCostItem = function(input) {
    let item = $($(input).closest('tr')[0]).find('.orderCostItemPrice')[0];
    
    return parseInt($(item).html(), 10);
};

Order.updateAreaSelection = function() {
    Order.areaDrawOptionText(false); 
    $(':focus').blur();
    
    let option = $('#areaSelectInput').find(":selected").val();
    
    if (option === "0") {
        $('#orderServiceAreaAttic').prop("disabled", true);
        $('#orderServiceAreaAttic').prop("checked", false);
        $('#orderServiceAreaSouterrain').prop("disabled", true);
        $('#orderServiceAreaSouterrain').prop("checked", false);
    } else {
        $('#orderServiceAreaAttic').prop("disabled", false);
        $('#orderServiceAreaSouterrain').prop("disabled", false);
    }
    
    let price = Order.getAreaPriceByOrder(option);
    
    if (price > 0) {
        price += ":-";
    } else {
        price = "";
    }
    
    $('#orderServiceAreaCost').html(price);
};

Order.getAreaPriceByOrder = function(order) {
    let discount = 0;
    
    if (Order.p.inspectionIsSelected) {
        discount = Order.p.areaDiscount;
    }
    
    switch(order) {
        case "1": return Order.p.dAreaPrice50 - discount;
            break;
        case "2": return Order.p.dAreaPrice100 - discount;
            break;
        case "3": return Order.p.dAreaPrice150 - discount;
            break;
        case "4": return Order.p.dAreaPrice300 - discount;
            break;
        default : return 0;
            break;
    }
};

Order.updateAreaPrice = function() {
    
};

Order.areaDrawOptionText = function(withPrice) {
    let options = $('#areaSelectInput').find('option');
    
    for (let i = 1; i <= 4; i++) {
        $(options[i]).html(Order.getAreaOptionText(i, withPrice));
    }
};

Order.getAreaOptionText = function(option, withPrice = true) {
    let discount = 0;
    
    if (Order.p.inspectionIsSelected) {
        discount = Order.p.areaDiscount;
    }
    
    let returnValue = "";
    switch (option) {
        case 1: 
            returnValue = "1-50 kvm&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;";
            returnValue += (withPrice) ? (Order.p.dAreaPrice50 - discount )+":-" : "";
        break;
        case 2: 
            returnValue = "51-100 kvm&nbsp;&nbsp;&nbsp;";
            returnValue += (withPrice) ? (Order.p.dAreaPrice100 - discount )+":-" : "";
        break;
        case 3: 
            returnValue = "101-150 kvm&nbsp;&nbsp;";
            returnValue += (withPrice) ? (Order.p.dAreaPrice150 - discount )+":-" : "";
        break;
        case 4: 
            returnValue = "151-300 kvm&nbsp;&nbsp;";
            returnValue += (withPrice) ? (Order.p.dAreaPrice300 - discount )+":-" : "";
        break;
            
    }
    
    return returnValue;
};

Order.showHideAreaInfo = function() {
    let isChecked = document.getElementById("orderServiceArea").checked;
    
    if (isChecked) {
        $('.areaSelectHideShow').show("fast", "swing");
        Order.updateAreaSelection();
    } else {
        $('.areaSelectHideShow').hide("fast", "swing");
        $('#orderServiceAreaCost').html("");
    }
    
    PageH.updateMasonry();
};

Order.orderTypeEvent = function(clickedElement) {
    if ($(clickedElement).find('input').prop("disabled")) {
        return;
    }
    
    $('.orderTypeChoice').each(function() {
        if (this !== clickedElement && $(this).find('input').prop("disabled") !== true) {
        //if (this !== clickedElement) {
            $(this).find('input').prop("checked", false);
        }
    });
    
    if ($(clickedElement).find('input').prop("checked") === true) {
        Order.p.inspectionIsSelected = true;
        Order.disableChoices(clickedElement, true);
        if ($(clickedElement).find('input').attr('id') === 'orderTypeNotar'
            || $(clickedElement).find('input').attr('id') === 'orderTypeLejon'){
            $('#orderPayerSeller').prop("checked", true);
            Order.toggleOrderPayerInputs(true);
            Order.updateEnergyDeclarationPrice(true);
        }
    } else {
        Order.p.inspectionIsSelected = false;
        Order.disableChoices(clickedElement, false);
        if ($(clickedElement).find('input').attr('id') === 'orderTypeNotar' 
                || $(clickedElement).find('input').attr('id') === 'orderTypeLejon') {
            $('#orderPayerParent input').prop("checked", false);
            Order.toggleOrderPayerInputs(false);
            Order.updateEnergyDeclarationPrice(false);
        }
    }
    
    let orderTypeNormalEnergyChecked = $('#orderTypeNormalEnergy').prop("checked");
    let orderTypePlusEnergyChecked = $('#orderTypePlusEnergy').prop("checked");
    
    Order.toggleEnergyService(orderTypeNormalEnergyChecked || orderTypePlusEnergyChecked);
    Order.updateAreaSelection();
    Order.showHideAreaInfo();
    Order.updateTotalCost();
};

Order.toggleEnergyService = function(disable) {
    $('#orderServiceEnergy').prop("disabled", disable);
    if (disable) {
        $('#orderServiceEnergy').prop("checked", !disable);
    }
};

Order.updateEnergyDeclarationPrice = function(isNotarLejonSelected) {
    if (isNotarLejonSelected) {
        $('#orderServiceEnergyCost').html("2800:-");
    } else {
        $('#orderServiceEnergyCost').html(Order.p.dPriceEnergy + ":-");
    }
};

Order.toggleOrderPayerInputs = function(disable) {
    $('#orderPayerParent input').each(function() {
        if ($(this).attr('id') !== 'orderPayerSeller') {
            $(this).prop("disabled", disable);
        }
    });
};

Order.disableChoices = function(clickedElement, disable) {
    $('.orderTypeItem').each(function() {
        if (this !== clickedElement) {
            $(this).find('input').prop("checked", false);
            $(this).find('input').prop("disabled", disable);
        }
    });
};

Order.setRealtorInfo = function() {
    let realtor = Globals.getCurrentClient();
    let realtorName;
    
    if (typeof realtor !== 'undefined') {
        realtorName = realtor.firstName + ' ' + realtor.lastName;
    }
    
    //console.log(realtor);
    if (JSON.stringify(Order.currentOrder) !== "{}") {
        realtorName = Order.currentOrder.broker;
        realtor = {
            company     : Order.currentOrder.brokerOffice,
            phoneNumber : Order.currentOrder.brokerPhone,
            email       : Order.currentOrder.brokerEmail
        };
    }
    
    if (typeof realtor !== 'undefined') {
        $('#brokerInfoOffice').val(realtor.company);
        $('#brokerInfoBroker').val(realtorName);
        $('#brokerInfoPhone').val(realtor.phoneNumber);
        $('#brokerInfoEmail').val(realtor.email);
    }
    
    switch(realtor.accountType) {
        case 1:
            break;
        case 2:
            Order.showHideVilladeklarerat(true);
            $('#insuranceCompany').html("GarBo");
            break;
        case 3:
            Order.showHideKvalitetssakrad(true);
            break;
    }
    
};

/*Order.specialOrderTypeEvent = function() {
    if (document.getElementById('specialOrderTypeNotar').checked) {
        Order.setPayer(Globals.const.ORDER_PAYER_SELLER);
        Order.togglePayerField(true);
        Order.showHideVilladeklarerat(true);
        Order.setOrderTypeVilladeklarerat(true);
        Order.disableOrderType(true);
    } else {
        Order.togglePayerField(false);
        Order.showHideVilladeklarerat(false);
        Order.setOrderTypeVilladeklarerat(false);
        Order.disableOrderType(false);
    }
    
    Order.updateTotalCost();
    
};*/

Order.togglePayerField = function(disable) {
    if (disable) {
        $('#orderPayerParent input').prop("disabled", true);
    } else {
        $('#orderPayerParent input').prop("disabled", false);
    }
};

Order.showHideVilladeklarerat = function(show) {
    if (show) {
        $('#notarOrderType').show();
    } else {
        $('#notarOrderType').hide();
    }
};

Order.showHideKvalitetssakrad = function(show) {
    if (show) {
        $('#lejonOrderType').show();
    } else {
        $('#lejonOrderType').hide();
    }
};

Order.setOrderTypeVilladeklarerat = function(setTo) {
    $('#orderTypeNotar').prop("checked", setTo);
    
    
    if (setTo) { 
        $('#notarOrderType').find('.orderTypeChoice').trigger('change');
        Order.orderTypeEvent($('#notarOrderType').find('.orderTypeChoice')[0]);
    }
    
    $('#orderTypeNotar').trigger('change');
    //$('#orderTypeNotar').trigger('click');
};

Order.setORderTypeKvalitetssakrad = function(setTo) {
    $('#orderTypeLejon').prop("checked", setTo);
    
    
    if (setTo) { 
        $('#lejonOrderType').find('.orderTypeChoice').trigger('change');
        Order.orderTypeEvent($('#lejonOrderType').find('.orderTypeChoice')[0]);
    }
    
    $('#orderTypeLejon').trigger('change');
    //$('#orderTypeNotar').trigger('click');
};

Order.disableOrderType = function(disable) {
    $('.orderTypeChoice').each(function() {
        $(this).find('input').prop("disabled", disable);
    });
};