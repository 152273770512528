import {PageH}          from './pageh.js';
import {ValidationH}    from './ValidationH.js';
import {NotificationH}  from './NotificationH.js';
import {APICaller}      from './APICaller.js';
import {Misc}           from './Misc.js';

export const LoginH = {};

LoginH.showLoginModal = function() {
    let dialog = $('#loginModal');
    
    $(dialog).find('#loginButton').off().on('click', function() {
        //PageH.loadPage("user/order.html");
        //LoginH.hideLoginModal();
        LoginH.login();
    });
    
    $(dialog).find("#modalLoginFormEmail").keypress(function(e) {
        if (e.which === 13) {
            LoginH.login();
        }
    });
        
    $(dialog).find("#modalLoginFormPassword").keypress(function(e) {
        if (e.which === 13) {
            LoginH.login();
        }
    });
    
    $(dialog).find('#loginFormForgotPassword').off().on('click', function() {
        LoginH.hideLoginModal();
        let rpDialog = $('#resetPasswordModal');
        let rpInput = $('#resetPasswordModalEmail');
        
        $(rpDialog).find('#resetPasswordModalEmail').off().on("keypress", function(e) {
            if (e.which === 13) {
                LoginH.resetPasswordRequest();
            }
        });
        
        $(rpDialog).find("#resetPasswordButton").off().on('click', function() {
            LoginH.resetPasswordRequest();
        });
        
        rpDialog.modal({
            backdrop: 'static',
            keyboard: false,
            show: true
        }).modal('show');
    });
    
    
    
    dialog.modal({
        backdrop: 'static',
        keyboard: false,
        show: true
    }).modal('show');
};

LoginH.resetPasswordRequest = async function() {
    if (ValidationH.validateForm(document.getElementById('resetPasswordModalForm'))) {
        var email = document.getElementById('resetPasswordModalEmail').value;
        
        var params = "data=" + encodeURIComponent(email);
        
        Misc.addSpinnerToButton($('#resetPasswordButton'));
        
        let response = await APICaller.commands.requestPasswordReset(params);
        
        if (response['code'] === 1) {
            let result = response['data'];
            if (result === 1) {
                NotificationH.showSimpleDialogWithCustomEvent("Återställning skickad", "Det har skickats en återställningslänk till angiven E-postadress. Länken är giltig i 5 minuter."
                        , function() {
                            location.reload();
                        });
            } else {
                NotificationH.showSimpleDialog("Error", "Det gick inte att skicka en återställninglänk till angiven epostadress. Kontrollera att det är rätt adress och försök igen.");
            }
        } else {
            NotificationH.showSimpleDialog("Okänt fel", "Något gick fel, kontakta systemadministratör och beskriv problemet.");
        }
        
        Misc.removeSpinnerToButton($('#resetPasswordButton'));
    }
};

LoginH.hideLoginModal = function() {
    $('#loginModal').modal('hide');
};

LoginH.hideChangePasswordDialog = function() {
    $('#createNewPasswordModal').modal('hide');
};

LoginH.hideNewUserPasswordDialog = function() {
    $('#newUserPasswordModal').modal('hide');
};

LoginH.login = async function() {
    if (ValidationH.validateForm(document.getElementById('modalLoginForm'))) {
        var email = document.getElementById('modalLoginFormEmail').value;
        var password = document.getElementById('modalLoginFormPassword').value;

        var userInfo = new Object();
        userInfo.email = email;
        userInfo.password = password;

        var params = "data=" + encodeURIComponent(JSON.stringify(userInfo));

        let response = await APICaller.commands.login(params);
        
        if (response['code'] === 1) {
            var result = response['data'];
            if (result === 1) {
                location.reload();
            } else if (result === 0) {
                NotificationH.showSimpleDialog("Fel e-post eller lösenord", "E-post och lösenord matchar inte med varandra, var god försök igen.");
            }
        } else {
            NotificationH.showSimpleDialog("Okänt fel", "Ett fel uppstod vid försök till inloggning. Prova igen senare.");
        }
    }
};

LoginH.showNewUserPasswordModal = function() {
    let dialog = $('#newUserPasswordModal');

    $(dialog).find('#newUserPasswordModalPassword').off().on('keypress', function(e) {
        if (e.which === 13) {
            LoginH.newUserPasswordModalButtonEvent();
        }
    });
    
    $(dialog).find('#newUserPasswordModalRetype').off().on('keypress', function(e) {
        if (e.which === 13) {
            LoginH.newUserPasswordModalButtonEvent();
        }
    });
    
    $(dialog).find('#newUserPasswordModalButton').off().on('click', function() {
        LoginH.newUserPasswordModalButtonEvent();
    });
    
    dialog.modal({
        backdrop: 'static',
        keyboard: false,
        show: true
    }).modal('show');
    
    
};

LoginH.newUserPasswordModalButtonEvent = async function() {
    let password = $('#newUserPasswordModalPassword').val();
        let passwordRetype = $('#newUserPasswordModalRetype').val();
        if (ValidationH.validateForm(document.getElementById("newUserPasswordModalForm"))) {
            if (password === passwordRetype) {
                let result = await LoginH.setNewUserPasswordWithHash(password);
                
                if (result == 1) {
                    LoginH.hideNewUserPasswordDialog();
                    NotificationH.showSimpleDialogWithCustomEvent("Lösenord skapat", 'Ditt lösenord har sparats. Tryck på "Ok" för att gå till inloggning.', function() {
                        window.location.replace(window.location.href.split("?")[0]);
                    });
                } else {
                    NotificationH.showSimpleDialog("Något gick fel", "Det gick inte att ändra lösenordet. Begär en ny lösenordsåterställning och genomför bytet inom 5 minuter.");
                }
                
            } else {
                NotificationH.showSimpleDialog("Lösenord matchar inte", "Lösenordet och bekräftelsen av lösenordet stämmer inte överens med varandra.");
            }
        }
};

LoginH.setNewUserPasswordWithHash = async function(password) {
    let hash = LoginH.getUrlParams("nuhash");
    let email        = LoginH.getUrlParams("user");
    
    let paramObject = {
        password    : password,
        hash        : hash,
        email       : email
    };
    
    let params = "data=" + JSON.stringify(paramObject);
    
    let result = await APICaller.commands.setNewUserPasswordWithHash(params);
    
    if (result['code'] === 1) {
        return result['data'];
    } else {
        return 0;
    }
};

LoginH.showCreateNewPasswordModal = function() {
    let dialog = $('#createNewPasswordModal');
    
    $(dialog).find('#createNewPasswordModalPassword').off().on('keypress', function(e) {
        if (e.which === 13) {
            LoginH.createNewPasswordModalButtonEvent();
        }
    });
    
    $(dialog).find('#createNewPasswordModalPasswordRetype').off().on('keypress', function(e) {
        if (e.which === 13) {
            LoginH.createNewPasswordModalButtonEvent();
        }
    });
    
    $(dialog).find('#createNewPasswordModalButton').off().on('click', function() {
        LoginH.createNewPasswordModalButtonEvent();
    });
    
    dialog.modal({
        backdrop: 'static',
        keyboard: false,
        show: true
    }).modal('show');
};

LoginH.createNewPasswordModalButtonEvent = async function() {
    let password = $('#createNewPasswordModalPassword').val();
    let passwordRetype = $('#createNewPasswordModalPasswordRetype').val();
    if (ValidationH.validateForm(document.getElementById("createNewPasswordModalForm"))) {
        if (password === passwordRetype) {
            let result = await LoginH.setNewPasswordWithHash(password);

            if (result == 1) {
                LoginH.hideChangePasswordDialog();
                NotificationH.showSimpleDialogWithCustomEvent("Lösenord ändrat", 'Ditt lösenord har ändrats. Tryck på "Ok" för att gå till inloggning.', function() {
                    window.location.replace(window.location.href.split("?")[0]);
                });
            } else {
                NotificationH.showSimpleDialog("Något gick fel", "Det gick inte att ändra lösenordet. Begär en ny lösenordsåterställning och genomför bytet inom 5 minuter.");
            }

        } else {
            NotificationH.showSimpleDialog("Fel lösenord", "Lösenordet och bekräftelsen av lösenordet stämmde inte överens med varandra.")
        }
    }
};

LoginH.setNewPasswordWithHash = async function(password) {
    let recoveryhash = LoginH.getUrlParams("recoveryhash");
    let email        = LoginH.getUrlParams("user");
    
    let paramObject = {
        password    : password,
        recoveryHash: recoveryhash,
        email       : email
    };
    
    let params = "data=" + JSON.stringify(paramObject);
    
    let result = await APICaller.commands.setNewPasswordWithHash(params);
    
    if (result['code'] === 1) {
        return result['data'];
    } else {
        return 0;
    }
    
};

LoginH.doLoginCheck = async function() {
    let recoveryhash    = LoginH.getUrlParams("recoveryhash");
    let newUserHash     = LoginH.getUrlParams("nuhash");
    
    if (recoveryhash === false && newUserHash === false) {
        return true;
    }
    
    if (recoveryhash) {
        await APICaller.commands.logout();
        LoginH.hideLoginModal();
        LoginH.showCreateNewPasswordModal();
    } else {
        await APICaller.commands.logout();
        LoginH.hideLoginModal();
        LoginH.showNewUserPasswordModal();
    }
    
    return false;
};

LoginH.getUrlParams = function(paramToLookFor) {
    let url = window.location.search.substring(1);
    let urlVariables = url.split('&');

    for (let i = 0; i < urlVariables.length; i++) {
        let param = urlVariables[i].split('=');
        
        if (param[0] === paramToLookFor) {
            return decodeURIComponent(param[1]);
        }
    }
    
    return false;
};

LoginH.logout = async function() {
    await APICaller.commands.logout();
    
    let url = window.location.href.split("?");
    
    if (url.length > 1) {
        window.location.replace(window.location.href.split("?")[0]);
    } else {
        location.reload();
    }
};