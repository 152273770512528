import List         from 'list.js'
import {APICaller}  from './APICaller.js';
import {PageH}      from './pageh.js';
import {DateTimeH}  from './DateTimeH.js';
import {Globals}    from './Globals.js';
import {Order}      from './order.js';

export const OrderH = {};

OrderH.orderList;
OrderH.orderListOptions;
OrderH.orders;

OrderH.allreadyFetchedOrderStatus;

OrderH.init = function() {
    OrderH.allreadyFetchedOrderStatus = [0,0,0];
    OrderH.orders = new Array();
    //OrderH.fetchHistory();
    OrderH.fetchOrdersByStatus(OrderH.getCheckedOrderStatusFromGUI());
    OrderH.initEvents();
};

OrderH.initWithNewOnly = function() {
    $('#historyMainHeader').html("Beställningar");
    OrderH.setStatusCheckboxes([1,0,0]);
    OrderH.init();
};

OrderH.initEvents = function() {
    $('.orderHistoryStatusCheckbox').on("change", OrderH.statusCheckboxChangeEvent);
};

OrderH.setStatusCheckboxes = function(statusArray) {
    $('#orderHistoryStatusNew').prop("checked", statusArray[0]);
    $('#orderHistoryStatusOngoing').prop("checked", statusArray[1]);
    $('#orderHistoryStatusDone').prop("checked", statusArray[2]);
};

OrderH.statusCheckboxChangeEvent = async function() {
    let orderStatusFromGUI = OrderH.getCheckedOrderStatusFromGUI();
    
    let orderStatusToFetch = new Array();
    
    for (let i = 0; i < orderStatusFromGUI.length; i++) {
        if (OrderH.allreadyFetchedOrderStatus[orderStatusFromGUI[i]] === 0) {
            OrderH.allreadyFetchedOrderStatus[orderStatusFromGUI[i]] = 1;
            orderStatusToFetch.push(orderStatusFromGUI[i]);
        }
    }
    
    if (orderStatusToFetch.length > 0) {
        await OrderH.fetchOrdersByStatus(orderStatusToFetch);
    }

    OrderH.filterListByStatus(orderStatusFromGUI);
};

OrderH.filterListByStatus = function(statusArray) {
    OrderH.orderList.filter(function(item) {
        for (let i = 0; i < statusArray.length; i++) {
            if (OrderH.orders[item.values().orderListId].status === statusArray[i]) {
                return true;
            }
        }
        return false;
    });
};

OrderH.addOrders = function(orders) {
    $(orders).each(function (i, order) {
        OrderH.orders[order.orderId] = order;
    });
};


OrderH.fetchOrdersByStatus = async function(statusArray) {
    if (statusArray.length === 0) {
        return;
    }
    
    let params = "data=" + JSON.stringify(statusArray);

    let result = await APICaller.commands.getUserOrdersByStatus(params);
    
    if (result['code'] === 1) {
        if (result['data'] != 0) {
            OrderH.addOrders(JSON.parse(result['data']));
        }
    }
    
    Globals.addStoreOrders(OrderH.orders);
    
    OrderH.drawOrderList();
};

OrderH.getCheckedOrderStatusFromGUI = function() {
    let returnArray = new Array();
    
    if ($('#orderHistoryStatusNew').is(":checked")) returnArray.push(Globals.const.ORDER_STATUS_NEW);
    if ($('#orderHistoryStatusOngoing').is(":checked")) returnArray.push(Globals.const.ORDER_STATUS_ONGOING);
    if ($('#orderHistoryStatusDone').is(":checked")) returnArray.push(Globals.const.ORDER_STATUS_DONE);
    
    return returnArray;
};

OrderH.fetchHistory = async function() {
    let result = await APICaller.commands.getOrderHistory();
    
    if (result['code'] === 1) {
        if (result['data'] == 0) {
            OrderH.orders = new array();
        } else {
            OrderH.orders = JSON.parse(result['data']);
        }
    }

    //OrderH.createMockOrder();
    OrderH.drawOrderList();
};

OrderH.drawOrderList = function() {
    OrderH.orderListOptions = {
        valueNames: [
            'orderListObject'
            , 'orderListDate'
            , { name: 'orderListId', attr: 'oId'}
        ],
        item: OrderH.getOrderListTemplate()
    };
    
    if (typeof OrderH.orderList === 'object') {
        OrderH.orderList.clear();
    }

    OrderH.orderList = new List('order-list', OrderH.orderListOptions, OrderH.getOrdersInListformat(OrderH.orders));

    OrderH.setListItemEvents();
    
    OrderH.drawStatusColorInGUI();
    
    PageH.updateMasonry();
};

OrderH.drawStatusColorInGUI = function() {
    $('.order-status-color').each(function(i, element) {
        $(element).removeClass("c-purple-500 c-yellow-500 c-green-500");
        let color = OrderH.getStatusColorByOrderId($(element).closest(".orderListId").attr('oId'));
        $(element).addClass(color);
    });
            
};

OrderH.getStatusColorByOrderId = function(orderId) {
    switch (OrderH.orders[orderId].status) {
        case 0: return "c-green-500";
        case 1: return "c-yellow-500";
        case 2: return "c-purple-500";
    }
};

OrderH.setListItemEvents = function() {
    let items = $("#order-list li");
    
    $(items).each(function(i, el) {
        
        $(el).on("click", function() {
            let oId         = $($(el).find('.orderListId')[0]).attr("oId");
            let page        = "user/order.html";
            let dataInit    = "order";
            let funcpack    = [Order.loadOrder, oId];
            PageH.loadPage(page, true, dataInit, funcpack);
        });
    });
};

OrderH.getOrdersInListformat = function(orders) {
    let returnList = new Array();
    
    orders.forEach((order) => {
        returnList.push({
            orderListObject     : order.objectProperty,
            orderListDate       : DateTimeH.dateTimeToDate(order.updatedAt),
            orderListId         : order.orderId
        });
    });
    
    return returnList;
};

OrderH.getOrderListTemplate = function() {
    return '<li class="list-group-item">'
                +'<div class="container orderListId">'
                    +'<div class="row">'
                        /*+'<div class="col-auto pL-0">'
                            +'<div class="peer">'
                                +'<img class="w-2r bdrs-50p" src="assets/static/images/abstract-user-flat-1.png" alt="">'
                            +'</div>'
                        +'</div>'*/
                        +'<div class="col pL-0">'
                            +'<p class="mb-0 mT-2 clientListText"><b class="orderListObject">Blåklintdalen 43</b></p>'
                            +'<p class="mb-0 clientListText orderListDate">2023-02-25</p>'
                        +'</div>'
                        +'<div class="col-auto pL-0">'
                            +'<span class="icon-holder">'
                                +'<i class="c-green-500 ti-control-record order-status-color"></i>'
                            +'</span>'
                        +'</div>'
                    +'</div>'
                +'</div>'
            +'</li>';
};

OrderH.createMockOrder = function() {
    let orders = new Array();
    
    let object1 = {
        object      : "Stenskönan 145",
        date        : "2023-07-23"
    };
    
    orders.push(object1);
    
    let object2 = {
        object      : "Per lagers bottenvik 5",
        date        : "2023-06-19"
    };
    
    orders.push(object2);
    OrderH.orders = orders;
};