//import sidebar from './';
import Masonry      from 'masonry-layout';
import {Order}      from './order.js';
import {LoginH}     from './loginh.js';
import {Globals}    from './Globals.js';
import {Clients}    from './clients.js';
import {AddClient}  from './addclient.js';
import {OrderHA}    from './orderha.js';
import {OrderH}     from './orderh.js';
import {NewOrders}  from './NewOrders';
import {APICaller}  from './APICaller.js';
import {AccountS}   from './accountsettings.js';


export const PageH = {};

PageH.p = {};

PageH.p.newOrdersCount      = 0;
PageH.p.ongoingOrdersCount  = 0;

PageH.test = function() {
    alert('Hej');
};

PageH.loadSideBar = function(sFldr) { //function that loads sidebar via AJAX
    {
        (
            $.ajax({	//create an ajax request to load menu.html
                type: "GET",
                url: 'components/html/' + sFldr + '/menu.html',
                dataType: "html",	//expect html to be returned
                success: function (msg) {
                    //load the returned html into pageContent
                    $('#sidebar-menu').html(msg);
                    PageH.initSideBar();
                    //$('.loader').css('visibility', 'hidden');	//and hide the spinner
                }
            })
        );
    }
};

PageH.openClientMenu = function() {
    let clientMenu = $('.navClientMenu')[0];
    $(clientMenu)
        .parent()
        .children('.dropdown-menu')
        .slideDown(200, () => {
          $(clientMenu).parent().addClass('open');
        });
};

PageH.setClientNameInMenu = function() {
    $('#menuClientName').html(Globals.getCurrentClient().firstName + ' ' + Globals.getCurrentClient().lastName);
};

PageH.initSideBar = function() {
    $('.sidebar .sidebar-menu li a').on('click', function () {
        const $this = $(this);

        /*if ($this.parent().hasClass('open')) {
          $this
            .parent()
            .children('.dropdown-menu')
            .slideUp(200, () => {
              $this.parent().removeClass('open');
            });
        } else {
          $this
            .parent()
            .parent()
            .children('li.open')
            .children('.dropdown-menu')
            .slideUp(200);

          $this
            .parent()
            .parent()
            .children('li.open')
            .children('a')
            .removeClass('open');

          $this
            .parent()
            .parent()
            .children('li.open')
            .removeClass('open');

          $this
            .parent()
            .children('.dropdown-menu')
            .slideDown(200, () => {
              $this.parent().addClass('open');
            });
        }*/

        let sTarget = $(this).attr("data-target");
        if (sTarget) {
            let pageInit = $(this).attr("data-init");
            PageH.loadPage(sTarget, true, pageInit);
            //ContentSubMenu.showCurrentUserMenu(false);
            //ContentSubMenu.showActivitiesSubMenu(false);
        }
    });

  // Sidebar Activity Class
  const sidebarLinks = $('.sidebar').find('.sidebar-link');

  sidebarLinks
    .each((index, el) => {
      $(el).removeClass('active');
    })
    .filter(function () {
      const href = $(this).attr('href');
      const pattern = href[0] === '/' ? href.substr(1) : href;
      return pattern === (window.location.pathname).substr(1);
    })
    .addClass('active');
    
    
    
};

/***
 * 
 * @param {string}  sPage               Page to load.
 * @param {bool}    pushState           Add to backnavigation (not implemented).
 * @param {bool}    pageInit            Run loaded pages init function.
 * @param {array}   triggerAfterLoad    [0] = Function to run after page is loaded.
 *                                      [1] = Data to pass to function.
 */

PageH.loadPage = function(sPage, pushState = true, pageInit = false, triggerAfterLoad = false) { //function that loads mainpage via AJAX
    //PageHandler.closeSideMenuOnMobile();
    window.scrollTo(0,0);
    /*if (pushState) {
        PageHandler.setPushState(sPage);
    }*/
    {
        (
            $.ajax({	//create an ajax request to load menu.html
                type: "GET",
                url: 'components/html/' + sPage,
                dataType: "html",	//expect html to be returned
                success: function (msg) {
                    //load the returned html into pageContent
                    $('#mainContent').html(msg);
                    PageH.updateMasonry();
                    if (pageInit !== false) PageH.initLoadedPage(pageInit);
                    if (triggerAfterLoad !== false) triggerAfterLoad[0](triggerAfterLoad[1]);
                    //$('.loader').css('visibility', 'hidden');	//and hide the spinner
                }
            })
        );
    }
};

PageH.getFileNameFromURL = function(url) {
    let start = url.lastIndexOf('/');
    let end = url.lastIndexOf('.');
    start   = (start === -1) ? 0 : (start + 1);
    end     = (end === -1) ? (url.length) : (end);
    return url.substring(start, end) + ".js";
};

PageH.initLoadedPage = function(pageToInit) {
    switch(pageToInit) {
        case "order":                   Order.init(); break;
        case "clients":                 Clients.init(); break;
        case "addclient":               AddClient.init(); break;
        case "orderhistoryall":         OrderHA.init(); break;
        case "orderhistory":            OrderH.init(); break;
        case "neworders":               NewOrders.init(); break;
        case "orderview":               OrderV.init(); break;
        case "orderhistorynew":         OrderHA.initWithNewOnly(); break;
        case "userorderhistorynew":     OrderH.initWithNewOnly(); break;
        case "accountsettingsasadmin":  AccountS.initAsAdmin(); break;
        case "accountsettings":         AccountS.init(); break;
    }
    //console.log(pageToInit);
};

PageH.setNavRight = function() {
    $('#navRightLogoutButton').on('click', function(){   LoginH.logout(); });
    $('#navRightUserFullName').html(Globals.userInfo.firstName + ' ' + Globals.userInfo.lastName);
    $('#navRightAccountSettings').on('click', PageH.accountSettingsEvent);
};

PageH.accountSettingsEvent = function() {
    let sTarget = $(this).attr("data-target");
    if (sTarget) {
        let pageInit = $(this).attr("data-init");
        PageH.loadPage(sTarget, true, pageInit);
    }


  // Sidebar Activity Class
  const sidebarLinks = $('.sidebar').find('.sidebar-link');

  sidebarLinks
    .each((index, el) => {
      $(el).removeClass('active');
    });
};

PageH.drawOrderCounts = async function() {
    PageH.getOngoingOrdersCount();
    PageH.getNewOrdersCount();
};

PageH.getOngoingOrdersCount = async function() {
    let statusArray = [Globals.const.ORDER_STATUS_ONGOING];
    let params = "data=" + encodeURIComponent(JSON.stringify(statusArray));
    let result = await APICaller.commands.getOrderCount(params);
    
    if (result['code'] === 1) {
        PageH.p.ongoingOrdersCount = result['data'];
    }
    
    PageH.drawOngoingOrdersCount();
};

PageH.drawOngoingOrdersCount = function() {
    
    if (PageH.p.ongoingOrdersCount > 0) {
        $('#menuCircleOngoingOrder').removeClass('d-none');
    } else {
        $('#menuCircleOngoingOrder').addClass('d-none');
    }
    
    $('#menuCircleOngoingOrderText').html(PageH.p.ongoingOrdersCount);
};

PageH.getNewOrdersCount = async function() {
    let statusArray = [Globals.const.ORDER_STATUS_NEW];
    let params = "data=" + encodeURIComponent(JSON.stringify(statusArray));
    let result = await APICaller.commands.getOrderCount(params);
    
    if (result['code'] === 1) {
        PageH.p.newOrdersCount = result['data'];
    }
    
    PageH.drawNewOrdersCount();
};

PageH.drawNewOrdersCount = function() {
    
    if (PageH.p.newOrdersCount > 0) {
        $('#menuCircleNewOrder').removeClass('d-none');
    } else {
        $('#menuCircleNewOrder').addClass('d-none');
    }
    
    $('#menuCircleNewOrderText').html(PageH.p.newOrdersCount);
};

PageH.updateMasonry = function() {
    if ($('.masonry').length > 0) {
        new Masonry('.masonry', {
          itemSelector: '.masonry-item',
          columnWidth: '.masonry-sizer',
          percentPosition: true
        });
    }
};