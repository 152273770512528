export const NotificationH = {};

NotificationH.showSimpleDialog = function(header, body) {
    $('#notificationSimpleModalLable').html(header);
    $('#notificationSimpleModalBody').html(body);
    
    
    $('#notificationSimpleModalButton').off().on('click', function() {
        $('#notificationSimpleModal').modal('hide');
    });
    
    $('#notificationSimpleModal').off().on('keypress', function(e) {
        $('#notificationSimpleModal').modal('hide');
    });
    
    $('#notificationSimpleModal').modal({
        backdrop: 'static',
        keyboard: true,
        show: true
    }).modal('show');
    
    setTimeout(function() {document.getElementById("notificationSimpleModal").focus();}, 500);
    
};

NotificationH.showSimpleDialogWithCustomEvent = function(header, body, buttonEvent) {
    $('#notificationSimpleModalLable').html(header);
    $('#notificationSimpleModalBody').html(body);
    
    
    $('#notificationSimpleModalButton').off().on('click', function() {
        $('#notificationSimpleModal').modal('hide');
        buttonEvent();
    });
    
    $('#notificationSimpleModal').off().on('keypress', function(e) {
        $('#notificationSimpleModal').modal('hide');
        buttonEvent();
    });
    
    $('#notificationSimpleModal').modal({
        backdrop: 'static',
        keyboard: true,
        show: true
    }).modal('show');
    
    setTimeout(function() {document.getElementById("notificationSimpleModal").focus();}, 500);
};