export const Misc = {};

Misc.p = {};

Misc.p.buttonSpinnerCode = '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>';

Misc.addSpinnerToButton = function(button) {
    $(button).html(Misc.p.buttonSpinnerCode + $(button).html());
    $(button).prop("disabled", true);
};

Misc.removeSpinnerToButton = function(button) {
    $(button).html($(button).html().replace(Misc.p.buttonSpinnerCode, ''));
    $(button).prop("disabled", false);
};