import List         from 'list.js'
import {APICaller}  from './APICaller.js';
import {PageH}      from './pageh.js';
import {DateTimeH}  from './DateTimeH.js';
import {Order}      from './order.js';
import {Globals}    from './Globals.js';

export const OrderHA = {};

OrderHA.orderList;
OrderHA.orderListOptions;
OrderHA.orders;

OrderHA.allreadyFetchedOrderStatus;

OrderHA.init = function() {
    OrderHA.allreadyFetchedOrderStatus = [0,0,0];
    
    OrderHA.orders = new Array();
    OrderHA.initEvents();
    //OrderHA.fetchOrder(1);
    
    OrderHA.fetchOrdersByStatus(OrderHA.getCheckedOrderStatusFromGUI());
    //OrderHA.fetchActiveOrders();
};

OrderHA.initWithNewOnly = function() {
    $('#historyMainHeader').html("Beställningar");
    OrderHA.setStatusCheckboxes([1,0,0]);
    OrderHA.init();
};

OrderHA.initEvents = function() {
    $('.orderHistoryStatusCheckbox').on("change", OrderHA.statusCheckboxChangeEvent);
};

OrderHA.setStatusCheckboxes = function(statusArray) {
    $('#orderHistoryStatusNew').prop("checked", statusArray[0]);
    $('#orderHistoryStatusOngoing').prop("checked", statusArray[1]);
    $('#orderHistoryStatusDone').prop("checked", statusArray[2]);
};

OrderHA.statusCheckboxChangeEvent = async function() {
    let orderStatusFromGUI = OrderHA.getCheckedOrderStatusFromGUI();
    
    let orderStatusToFetch = new Array();
    
    for (let i = 0; i < orderStatusFromGUI.length; i++) {
        if (OrderHA.allreadyFetchedOrderStatus[orderStatusFromGUI[i]] === 0) {
            OrderHA.allreadyFetchedOrderStatus[orderStatusFromGUI[i]] = 1;
            orderStatusToFetch.push(orderStatusFromGUI[i]);
        }
    }
    
    if (orderStatusToFetch.length > 0) {
        await OrderHA.fetchOrdersByStatus(orderStatusToFetch);
    }
    
    OrderHA.filterListByStatus(orderStatusFromGUI);
};

OrderHA.filterListByStatus = function(statusArray) {
    OrderHA.orderList.filter(function(item) {
        for (let i = 0; i < statusArray.length; i++) {
            if (OrderHA.orders[item.values().orderListId].status === statusArray[i]) {
                return true;
            }
        }
        return false;
    });
};

OrderHA.getCheckedOrderStatusFromGUI = function() {
    let returnArray = new Array();
    
    if ($('#orderHistoryStatusNew').is(":checked")) returnArray.push(Globals.const.ORDER_STATUS_NEW);
    if ($('#orderHistoryStatusOngoing').is(":checked")) returnArray.push(Globals.const.ORDER_STATUS_ONGOING);
    if ($('#orderHistoryStatusDone').is(":checked")) returnArray.push(Globals.const.ORDER_STATUS_DONE);
    
    return returnArray;
};

OrderHA.addOrders = function(orders) {
    $(orders).each(function (i, order) {
        OrderHA.orders[order.orderId] = order;
    });
};

OrderHA.fetchOrdersByStatus = async function(statusArray) {
    if (statusArray.length === 0) {
        return;
    }
    
    let params = "data=" + JSON.stringify(statusArray);

    let result = await APICaller.commands.getOrdersByStatus(params);
    
    if (result['code'] === 1) {
        if (result['data'] != 0) {
            OrderHA.addOrders(JSON.parse(result['data']));
        }
    }
    
    Globals.addStoreOrders(OrderHA.orders);
    
    OrderHA.drawOrderList();
};

OrderHA.fetchActiveOrders = async function() {
    let result = await APICaller.commands.getActiveOrders();
    
    if (result['code'] === 1) {
        try {
            OrderHA.orders = JSON.parse(result['data']);
        } catch(e) {
            OrderHA.orders = new Array();
        }
    }
    
    Globals.addStoreOrders(OrderHA.orders);
    
    OrderHA.drawOrderList();
};

OrderHA.fetchOrder = async function(orderId) {
    let params = "data=" + encodeURIComponent(orderId);
    
    let result = await APICaller.commands.getOrder(params);
    
    if (result['code'] === 1) {
        if (result['data'] == 0) {
            OrderHA.orders = new Array();
        } else {
            OrderHA.orders = new Array();
            OrderHA.orders.push(JSON.parse(result['data']));
        }
    }
    
    OrderHA.createMockOrder();
    OrderHA.drawOrderList();
};

OrderHA.drawOrderList = function() {
    OrderHA.orderListOptions = {
        valueNames: [
            'orderListName', 
            'orderListCompany', 
            'orderListObject', 
            'orderListDate',
            { name: 'orderListId', attr: 'oId'}],
        
        item: OrderHA.getOrderListTemplate()
    };
    
    if (typeof OrderHA.orderList === 'object') {
        OrderHA.orderList.clear();
    }
    
    OrderHA.orderList = new List('order-list', OrderHA.orderListOptions, OrderHA.getOrdersInListformat(OrderHA.orders));
    
    OrderHA.setListItemEvents();
    
    OrderHA.drawStatusColorInGUI();

    PageH.updateMasonry();
};

OrderHA.setListItemEvents = function() {
    let items = $("#order-list li");
    
    $(items).each(function(i, el) {
        
        $(el).on("click", async function() {
            let oId         = $($(el).find('.orderListId')[0]).attr("oId");
            
            var params = "data=" + encodeURIComponent(oId);
            let result = await APICaller.commands.setCurrentClientByOrderId(params);

            if (result['code'] === 1) {
                if (result['data'] == 0) {
                    NotificationH.showSimpleDialog("Error", "Det gick inte att öppna beställningen. Ladda om sidan och försök igen.");
                } else {
                    Globals.setCurrentClient(JSON.parse(result['data']));
                    PageH.setClientNameInMenu();
                    PageH.openClientMenu();
                    let page        = "user/order.html";
                    let dataInit    = "order";
                    let funcpack    = [Order.loadOrder, oId];
                    PageH.loadPage(page, true, dataInit, funcpack);
                }
            }
        });
    });
};

OrderHA.getOrdersInListformat = function(orders) {
    let returnList = new Array();
    
    orders.forEach((order) => {
        returnList.push({
            orderListName       : order.broker,
            orderListCompany    : order.brokerOffice,
            orderListObject     : order.objectProperty,
            orderListDate       : DateTimeH.dateTimeToDate(order.createdAt),
            orderListId         : order.orderId
        });
    });
    
    /*for (let i = 0; i < orders.length; i++) {
        returnList.push({
            orderListName       : orders[i].broker,
            orderListCompany    : orders[i].brokerOffice,
            orderListObject     : orders[i].objectProperty,
            orderListDate       : DateTimeH.dateTimeToDate(orders[i].createdAt),
            orderListId         : orders[i].orderId
        });
    }*/
    
    //console.log(orders);
    
    return returnList;
};

OrderHA.getOrderClickFunction = function(orderId) {
    let page        = "user/order.html";
    let dataInit    = "order";
    let funcpack    = [Order.loadOrder, orderId];
    let orderFunction = function() {
        PageH.loadPage(page, true, dataInit, funcpack);
    };
    return orderFunction;
};

OrderHA.drawStatusColorInGUI = function() {
    $('.order-status-color').each(function(i, element) {
        $(element).removeClass("c-purple-500 c-yellow-500 c-green-500");
        let color = OrderHA.getStatusColorByOrderId($(element).closest(".orderListId").attr('oId'));
        $(element).addClass(color);
    });
            
};

OrderHA.getStatusColorByOrderId = function(orderId) {
    switch (OrderHA.orders[orderId].status) {
        case 0: return "c-green-500";
        case 1: return "c-yellow-500";
        case 2: return "c-purple-500";
    }
};

OrderHA.getOrderListTemplate = function() {
    return '<li class="list-group-item">'
                +'<div class="container orderListId">'
                    +'<div class="row">'
                        +'<div class="col-auto pL-0">'
                            +'<div class="peer">'
                                +'<img class="w-2r bdrs-50p" src="assets/static/images/abstract-user-flat-1.png" alt="">'
                            +'</div>'
                        +'</div>'
                        +'<div class="col-3 pL-0">'
                            +'<p class="mb-0 mT-2 clientListText"><b class="orderListName">Jäcki Wilson</b></p>'
                            +'<p class="mb-0 clientListText orderListCompany">Hus och sånt</p>'
                        +'</div>'
                        +'<div class="col pL-0">'
                            +'<p class="mb-0 mT-2 clientListText"><b class="orderListObject">Blåklintdalen 43</b></p>'
                            +'<p class="mb-0 clientListText orderListDate">2023-02-25</p>'
                        +'</div>'
                        +'<div class="col-auto pL-0">'
                            +'<span class="icon-holder">'
                                +'<i class="c-green-500 ti-control-record order-status-color"></i>'
                            +'</span>'
                        +'</div>'
                    +'</div>'
                +'</div>'
            +'</li>';
};

OrderHA.createMockOrder = function() {
    let orders = new Array();
    
    let object1 = {
        firstName   : "Inna",
        lastName    : "Aldriktsson",
        company     : "Mäklarna",
        object      : "Stenskönan 145",
        date        : "2023-07-23"
    };
    
    orders.push(object1);
    
    let object2 = {
        firstName   : "Alexander",
        lastName    : "Halmström",
        company     : "Sköna hem",
        object      : "Per lagers bottenvik 5",
        date        : "2023-06-19"
    };
    
    orders.push(object2);
    OrderHA.orders = orders;
};
